import "./Contact.css";
import { useRef, useState } from "react";
import { AiFillGithub, AiFillLinkedin, AiOutlineMail } from "react-icons/ai";

const Contact = () => {
  const [emailFocused, setEmailFocused] = useState(false);
  const [nameFocused, setNameFocused] = useState(false);
  const [messageFocused, setMessageFocused] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [formError, setFormError] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const chatRef = useRef(null);
  const formRef = useRef(null);
  const successRef = useRef(null);

  ////////////////////////////////////////////////////////////////////////////
  // Placeholder doesn't go back inside input if value exists inside of it  //
  ////////////////////////////////////////////////////////////////////////////
  const valueExists = (value) => {
    if (value !== "") {
      return true;
    } else {
      return false;
    }
  };

  /////////////////////
  // Validate email //
  ///////////////////
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  ///////////////////////////
  // Validate text inputs //
  //////////////////////////
  const validateTextInputs = (text) => {
    if (text === "") {
      return false;
    } else {
      return true;
    }
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  //////////////////////////////////
  // Runs when form is submitted //
  ////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    // Validate Email
    if (!validateEmail(email)) {
      setEmailError(true);
      setSending(false);
      return;
    }
    // Validate Name
    if (!validateTextInputs(name)) {
      setNameError(true);
      setSending(false);
      return;
    }
    // Validate Message
    if (!validateTextInputs(message)) {
      setMessageError(true);
      setSending(false);
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: name,
        email: email,
        message: message,
      }),
    })
      .then(() => {
        setSent(true);
        ////////////////////////////////////////////
        // Remove Success classes after 2 seconds //
        ////////////////////////////////////////////
        setTimeout(() => {
          setSending(false);
          successRef.current.classList.remove("success");
          chatRef.current.classList.remove("success");
          formRef.current.classList.remove("success");
        }, 2000);

        if (formError) setFormError(false);
      })
      .catch((err) => {
        formError(err);
        setFormError(true);
      });
  };

  return (
    <section className="section" id="contact-section">
      <h2
        className={`section-title contact-h2 ${sent ? "success" : ""}`}
        ref={chatRef}
      >
        Lets Chat!
      </h2>
      {/************************************
       ** Shows on successful form submit **
       ************************************/}
      <div
        className={`success-message ${sent ? "success" : ""}`}
        ref={successRef}
      >
        <p className="success-text section-title">Talk to you soon!</p>
        <img
          src="./assets/success-img.svg"
          alt=""
          aria-hidden="true"
          className="success-img"
        />
      </div>
      {/*********
       ** Form **
       ********/}
      <form
        action="/contact"
        name="contact"
        method="post"
        data-netlify="true"
        id="contact-form"
        className={`container ${sent ? "success" : ""}`}
        ref={formRef}
      >
        <input type="hidden" name="form-name" value="contact" />

        {/****************
         ** Email Input **
         ****************/}
        <div className="input-container">
          <label
            htmlFor="email"
            className={`input-label ${emailFocused ? "active" : ""}`}
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            className="input focus-link"
            onFocus={() => {
              setEmailFocused(true);
              if (emailError) {
                setEmailError(false);
              }
            }}
            onBlur={() => {
              if (valueExists(email)) {
                return;
              } else {
                setEmailFocused(false);
              }
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p className={`error ${emailError ? "active" : ""}`}>
            Email is not valid
          </p>
        </div>
        {/****************
         ** Name Input **
         ****************/}
        <div className="input-container">
          <label
            htmlFor="name"
            className={`input-label ${nameFocused ? "active" : ""}`}
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            className="input focus-link"
            onFocus={() => {
              setNameFocused(true);
              if (nameError) {
                setNameError(false);
              }
            }}
            onBlur={() => {
              if (valueExists(name)) {
                return;
              } else {
                setNameFocused(false);
              }
            }}
            onChange={(e) => setName(e.target.value)}
          />
          <p className={`error ${nameError ? "active" : ""}`}>
            Name cannot be empty
          </p>
        </div>
        {/******************
         ** Message Input **
         ******************/}
        <div className="input-container">
          <label
            htmlFor="message"
            className={`message-label ${messageFocused ? "active" : ""}`}
          >
            Message
          </label>
          <textarea
            type="text"
            id="message"
            name="message"
            value={message}
            className="input contact-textarea focus-link"
            rows={10}
            onFocus={() => {
              setMessageFocused(true);
              if (messageError) {
                setMessageError(false);
              }
            }}
            onBlur={() => {
              if (valueExists(message)) {
                return;
              } else {
                setMessageFocused(false);
              }
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <p className={`error ${messageError ? "active" : ""}`}>
            Message cannot be empty
          </p>
        </div>
        {/***************
         ** Form error **
         **************/}
        <p className={`form-error ${formError ? "active" : ""}`}>{formError}</p>
        {/*************
         ** Form btn **
         ************/}
        <button
          className="contact-btn main-btn success"
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          {sending ? "Sending.." : "Submit"}
        </button>
      </form>
      {/********************
       ** Contact socials **
       *******************/}
      <ul className="contact-socials">
        {/* GitHub */}
        <a
          href="https://github.com/VictorMtzCode"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="My github"
        >
          <li className="contact-social">
            <AiFillGithub />
          </li>
        </a>
        {/* LinkedIn */}
        <a
          href="https://www.linkedin.com/in/victormartinezjr/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="My LinkedIn"
        >
          <li className="contact-social">
            <AiFillLinkedin />
          </li>
        </a>
        {/* Email */}
        <a
          href="mailto:victormtzcodes@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Email me"
        >
          <li className="contact-social">
            <AiOutlineMail />
          </li>
        </a>
      </ul>
      {/***************
       ** Rocket SVG **
       ***************/}
      <svg
        viewBox="0 0 1001 1001"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className="contact-rocket"
      >
        <g clipPath="url(#clip0_233_93)">
          <g id="Layer_5">
            <g id="Group">
              <g id="Group_2">
                <path
                  id="Vector"
                  d="M668.552 591.427C666.896 592.936 665.372 594.529 663.95 596.19C662.048 592.62 659.678 589.219 656.821 586.086C638.455 565.942 607.234 564.501 587.09 582.868C578.244 590.932 573.001 601.478 571.474 612.479C554.514 602.812 533.227 603.154 516.472 613.897C513.772 608.74 510.374 603.84 506.264 599.332C498.819 591.167 489.878 585.205 480.24 581.447L462.98 359.416L408.818 360.109L380.201 585.834C367.055 586.02 353.68 590.853 342.743 600.365C339.89 590.408 334.771 580.934 327.319 572.761C301.494 544.437 257.601 542.411 229.277 568.236C220.202 576.51 213.846 586.636 210.215 597.511C204.423 598.773 198.874 601.518 194.182 605.797C193.761 606.181 193.353 606.58 192.945 606.978C177.275 597.435 156.602 598.881 142.363 611.864C130.724 622.476 126.365 638.088 129.568 652.435C110.677 672.014 109.998 703.149 128.587 723.537C133.936 729.404 140.326 733.755 147.21 736.586C142.197 758.086 147.496 781.619 163.497 799.169C188.521 826.614 230.511 829.373 258.856 806.02C258.853 817.89 263.117 829.795 271.741 839.254C290.107 859.398 321.328 860.838 341.472 842.472C343.054 841.03 344.504 839.504 345.859 837.918C370.06 848.194 399.068 844.21 419.774 825.331C424.621 820.911 428.68 815.953 431.976 810.647C438.053 814.24 444.685 816.557 451.504 817.579C453.585 831.231 459.58 844.44 469.578 855.406C496.549 884.987 542.391 887.102 571.971 860.132C577.321 855.254 581.758 849.761 585.294 843.857C596.535 847.087 609.129 844.589 618.403 836.134C625.386 829.767 629.172 821.214 629.688 812.474C645.659 811.987 661.543 806.016 674.275 794.408C693.48 776.897 700.575 751.09 695.125 727.451C704.873 728.672 715.079 725.781 722.912 718.639C734.75 707.846 737.422 690.754 730.49 677.14C733.942 675.197 737.243 672.851 740.295 670.068C762.013 650.267 763.567 616.602 743.765 594.884C723.963 573.166 690.298 571.612 668.581 591.414L668.552 591.427Z"
                  fill="#E3E3E3"
                />
                <path
                  id="Vector_2"
                  d="M666.536 594.455C664.881 595.965 663.356 597.558 661.934 599.219C660.032 595.649 657.662 592.248 654.806 589.115C636.439 568.971 605.218 567.53 585.074 585.896C576.229 593.961 570.985 604.507 569.458 615.507C552.498 605.841 531.211 606.182 514.456 616.926C511.756 611.768 508.358 606.869 504.248 602.361C496.803 594.196 487.862 588.234 478.225 584.476L460.965 362.445L406.803 363.138L378.186 588.863C365.039 589.049 351.664 593.882 340.728 603.394C337.874 593.437 332.755 583.963 325.303 575.79C299.478 547.466 255.585 545.44 227.261 571.265C218.187 579.539 211.83 589.665 208.199 600.54C202.407 601.802 196.859 604.547 192.166 608.825C191.745 609.21 191.337 609.608 190.93 610.007C175.259 600.464 154.587 601.91 140.347 614.893C128.708 625.504 124.349 641.117 127.552 655.464C108.662 675.042 107.982 706.178 126.571 726.566C131.921 732.433 138.31 736.784 145.194 739.615C140.181 761.115 145.48 784.648 161.482 802.198C186.505 829.643 228.495 832.402 256.84 809.048C256.837 820.919 261.101 832.824 269.725 842.282C288.092 862.426 319.313 863.867 339.457 845.501C341.038 844.059 342.489 842.533 343.843 840.946C368.045 851.223 397.053 847.239 417.758 828.36C422.606 823.94 426.664 818.981 429.96 813.676C436.037 817.269 442.669 819.586 449.488 820.608C451.569 834.26 457.564 847.469 467.563 858.435C494.533 888.016 540.375 890.131 569.956 863.161C575.306 858.283 579.743 852.789 583.278 846.886C594.519 850.116 607.114 847.618 616.388 839.163C623.371 832.796 627.156 824.242 627.673 815.503C643.643 815.016 659.527 809.045 672.259 797.436C691.465 779.925 698.559 754.119 693.11 730.48C702.857 731.701 713.064 728.81 720.897 721.668C732.735 710.874 735.406 693.783 728.475 680.169C731.927 678.226 735.227 675.88 738.279 673.097C759.997 653.295 761.551 619.63 741.749 597.913C721.948 576.195 688.283 574.641 666.565 594.443L666.536 594.455Z"
                  fill="url(#paint0_linear_233_93)"
                />
                <path
                  id="Vector_3"
                  d="M628.253 613.499C623.635 617.71 619.915 622.536 617.071 627.728C590.89 616.779 559.613 621.155 537.267 641.529C534.924 643.665 532.76 645.922 530.755 648.264C529.88 647.141 528.975 646.03 527.998 644.959C514.247 629.877 493.81 624.531 475.335 629.291L457.98 380.471L414.4 381.029L387.4 627.243C378.814 629.24 370.602 633.384 363.626 639.744C359.873 643.167 356.743 647.008 354.197 651.116C351.212 646.151 347.707 641.416 343.658 636.975C310.53 600.64 254.219 598.042 217.885 631.17C215.254 633.569 212.808 636.096 210.528 638.716C191.643 631.969 169.758 635.603 153.907 650.055C141.855 661.044 135.717 676.086 135.643 691.202C135.435 728.674 169.269 759.682 203.632 746.12C206.127 749.881 208.94 753.5 212.073 756.936C227.987 774.39 249.251 784.057 271.076 785.716C269.888 799.478 274.216 813.68 284.262 824.697C303.343 845.625 335.778 847.122 356.706 828.041C363.031 822.273 367.573 815.277 370.312 807.759C393.014 816.331 419.596 812.164 438.757 794.693C439.185 794.303 439.586 793.896 440.008 793.498C453.119 800.991 470.087 799.594 481.844 788.874C482.132 788.612 482.399 788.341 482.673 788.077C483.413 789.081 484.202 790.066 485.065 791.012C487.686 793.887 490.686 796.212 493.902 798.003C494.191 825.793 546.236 855.164 580.493 836.692C591.36 830.83 637.011 806.569 625.173 762.609C629.941 759.899 634.481 756.64 638.7 752.793C651.824 740.827 659.726 725.286 662.303 709.011C676.496 709.779 690.983 705.082 702.319 694.747C724.753 674.292 726.358 639.525 705.903 617.09C685.448 594.655 650.681 593.051 628.246 613.506L628.253 613.499Z"
                  fill="white"
                />
                <path
                  id="Vector_4"
                  d="M624.456 616.963C619.837 621.174 616.118 626 613.273 631.191C587.092 620.243 555.815 624.618 533.469 644.992C531.127 647.128 528.963 649.385 526.957 651.728C526.082 650.604 525.178 649.494 524.201 648.422C510.449 633.34 490.012 627.994 471.537 632.755L454.183 383.934L410.602 384.492L383.602 630.707C375.016 632.703 366.804 636.847 359.829 643.207C356.075 646.63 352.946 650.471 350.399 654.579C347.414 649.614 343.91 644.879 339.86 640.438C306.732 604.104 250.421 601.505 214.087 634.633C211.456 637.032 209.011 639.559 206.731 642.179C187.845 635.433 165.96 639.066 150.109 653.518C138.057 664.507 131.919 679.549 131.845 694.665C131.637 732.137 165.472 763.146 199.834 749.583C202.329 753.344 205.142 756.963 208.275 760.4C224.189 777.854 245.453 787.521 267.278 789.179C266.09 802.942 270.418 817.143 280.464 828.161C299.545 849.088 331.981 850.585 352.908 831.504C359.233 825.737 363.775 818.74 366.515 811.222C389.216 819.794 415.798 815.627 434.959 798.157C435.387 797.766 435.788 797.36 436.21 796.962C449.321 804.454 466.289 803.057 478.046 792.338C478.334 792.075 478.601 791.804 478.875 791.541C479.615 792.545 480.405 793.53 481.267 794.475C483.888 797.35 486.888 799.676 490.111 801.46C490.4 829.249 542.446 858.621 576.702 840.148C587.569 834.286 633.22 810.025 621.382 766.066C626.151 763.356 630.69 760.097 634.91 756.249C648.033 744.283 655.935 728.743 658.513 712.468C672.705 713.236 687.193 708.539 698.528 698.203C720.963 677.748 722.567 642.981 702.112 620.547C681.657 598.112 646.89 596.508 624.456 616.963V616.963Z"
                  fill="url(#paint1_linear_233_93)"
                />
              </g>
            </g>
          </g>
          <g id="rocket">
            <g id="Group_3">
              <g id="Group_4">
                <path
                  id="Vector_5"
                  d="M476.275 486.648C476.275 486.648 455.301 501.85 486.876 542.988C488.36 544.921 491.239 545.012 492.828 543.166C501.367 533.251 523.518 504.235 506.381 487.567L476.275 486.648V486.648Z"
                  fill="#E81818"
                />
                <path
                  id="Vector_6"
                  d="M480.629 486.692C480.629 486.692 465.746 497.479 488.249 526.687C489.235 527.967 491.146 528.026 492.202 526.805C498.138 519.944 514.121 499.212 501.916 487.343L480.629 486.692V486.692Z"
                  fill="#E7A900"
                />
                <path
                  id="Vector_7"
                  d="M484.136 485.668C484.136 485.668 474.094 492.946 489.278 512.655C489.943 513.518 491.233 513.558 491.946 512.734C495.952 508.105 506.735 494.117 498.501 486.106L484.138 485.667L484.136 485.668Z"
                  fill="#FFFD01"
                />
                <path
                  id="Vector_8"
                  d="M507.1 528.389C503.058 535.174 498.383 541.077 494.986 545.022C493.669 546.551 491.757 547.394 489.742 547.338C487.72 547.279 485.853 546.327 484.619 544.719C471.033 527.019 465.079 511.626 466.922 498.967C468.379 488.959 474.352 484.528 474.606 484.343C475.117 483.974 475.733 483.783 476.362 483.803L506.469 484.722C507.179 484.744 507.856 485.03 508.365 485.527C520.774 497.595 515.21 514.771 507.098 528.389L507.1 528.389ZM475.075 492.428C474.017 494.203 472.971 496.699 472.522 500.02C471.493 507.617 473.541 520.937 489.135 541.256C489.391 541.589 489.726 541.646 489.903 541.651C490.077 541.656 490.409 541.618 490.676 541.311C497.706 533.145 519.215 505.673 505.15 490.377L477.309 489.528C476.75 490.081 475.91 491.032 475.078 492.429L475.075 492.428Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_9"
                d="M504.267 468.479C509.464 475.903 509.332 484.709 509.332 484.709C509.332 484.709 502.92 488.232 489.626 488.032C476.332 487.831 470.031 484.115 470.031 484.115C470.031 484.115 470.163 475.309 475.582 468.045"
                fill="#E2E8E8"
              />
              <path
                id="Vector_10"
                d="M511.882 486.228C511.616 486.674 511.232 487.051 510.762 487.309C510.471 487.469 503.502 491.21 489.581 491C475.662 490.789 468.808 486.84 468.523 486.672C467.605 486.13 467.047 485.136 467.064 484.07C467.07 483.672 467.278 474.213 473.206 466.271C474.186 464.957 476.047 464.686 477.36 465.666C478.676 466.647 478.94 468.519 477.964 469.82C474.617 474.304 473.525 479.55 473.168 482.289C475.687 483.278 481.142 484.935 489.672 485.064C498.194 485.192 503.697 483.703 506.252 482.787C505.98 480.042 505.044 474.762 501.836 470.181C500.897 468.837 501.225 466.987 502.565 466.046C503.908 465.106 505.759 465.433 506.7 466.775C512.382 474.893 512.306 484.353 512.3 484.752C512.293 485.281 512.143 485.787 511.882 486.225L511.882 486.228Z"
                fill="#000072"
              />
              <path
                id="Vector_11"
                d="M515.007 463.4C511.448 478.229 493.914 477.527 489.788 477.465C485.662 477.404 468.115 477.575 465 462.645C461.857 447.547 467.815 383.056 491.21 383.411C514.605 383.764 518.609 448.404 515.006 463.401L515.007 463.4Z"
                fill="#E81818"
              />
              <g id="Group_5" opacity="0.7">
                <path
                  id="Vector_12"
                  d="M485.905 477.322C479.947 475.773 473.556 472.156 471.797 463.709C468.733 448.991 474.319 387.357 496.263 384.573C494.69 383.837 493.005 383.431 491.212 383.405C467.816 383.053 461.858 447.543 465.001 462.643C467.488 474.577 479.202 476.858 485.906 477.321L485.905 477.322Z"
                  fill="#F2F2F2"
                />
              </g>
              <g id="Group_6" opacity="0.3">
                <path
                  id="Vector_13"
                  d="M509.118 463.174C506.313 474.869 494.82 476.903 488.06 477.211C490.147 475.214 491.818 472.555 492.669 468.99C495.783 456.054 493.228 406.171 477.447 392.489C480.529 387.785 484.21 384.561 488.552 383.666C509.084 389.622 512.552 448.901 509.12 463.175L509.118 463.174Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_14"
                d="M515.592 469.829C512.142 475.621 504.905 480.7 490.079 480.439L489.404 480.429C469.743 480.18 463.701 470.952 462.094 463.253C459.261 449.638 462.997 405.016 477.209 387.808C481.31 382.844 486.034 380.366 491.254 380.445C496.474 380.523 501.123 383.144 505.073 388.233C518.769 405.883 521.14 450.581 517.893 464.097C517.442 465.978 516.719 467.943 515.593 469.834L515.592 469.829ZM479.133 395.397C468.586 413.104 465.576 450.849 467.905 462.04C469.621 470.256 476.677 474.33 489.48 474.493L490.184 474.504C502.986 474.729 510.163 470.87 512.119 462.708C515.001 450.717 512.604 407.62 500.381 391.871C497.552 388.227 494.537 386.431 491.162 386.38C487.788 386.33 484.72 388.034 481.784 391.588C480.851 392.717 479.967 393.994 479.13 395.399L479.133 395.397Z"
                fill="#000072"
              />
              <path
                id="Vector_15"
                d="M517.792 440.783C517.516 441.245 517.074 441.61 516.517 441.775C499.317 446.919 481.222 446.649 464.182 440.997C463.145 440.674 462.475 439.63 462.65 438.525C462.84 437.31 463.98 436.483 465.194 436.673C465.314 436.692 465.435 436.722 465.551 436.76C481.726 442.134 498.909 442.393 515.241 437.511C516.419 437.157 517.659 437.828 518.01 439.005C518.195 439.627 518.098 440.266 517.79 440.782L517.792 440.783Z"
                fill="#000072"
              />
            </g>
            <g id="Group_7">
              <g id="Group_8">
                <path
                  id="Vector_16"
                  d="M365.371 483.37C365.371 483.37 344.398 498.571 375.972 539.71C377.456 541.643 380.335 541.734 381.925 539.888C390.464 529.973 412.614 500.956 395.478 484.289L365.371 483.37Z"
                  fill="#E81818"
                />
                <path
                  id="Vector_17"
                  d="M369.727 483.414C369.727 483.414 354.844 494.201 377.347 523.409C378.332 524.689 380.244 524.748 381.299 523.527C387.236 516.666 403.218 495.934 391.014 484.065L369.727 483.414Z"
                  fill="#E7A900"
                />
                <path
                  id="Vector_18"
                  d="M373.234 482.39C373.234 482.39 363.192 489.668 378.376 509.377C379.04 510.24 380.331 510.28 381.044 509.456C385.05 504.827 395.833 490.839 387.599 482.828L373.236 482.39L373.234 482.39Z"
                  fill="#FFFD01"
                />
                <path
                  id="Vector_19"
                  d="M396.197 525.109C392.153 531.897 387.479 537.798 384.081 541.745C382.764 543.274 380.854 544.118 378.838 544.062C376.815 544.005 374.95 543.051 373.715 541.444C360.129 523.744 354.174 508.349 356.017 495.691C357.472 485.686 363.447 481.252 363.701 481.068C364.211 480.698 364.827 480.508 365.457 480.528L395.564 481.446C396.273 481.469 396.951 481.755 397.459 482.251C409.868 494.319 404.304 511.495 396.194 525.11L396.197 525.109ZM364.169 489.15C363.111 490.926 362.064 493.421 361.616 496.742C360.587 504.339 362.634 517.66 378.229 537.978C378.483 538.31 378.82 538.368 378.998 538.372C379.172 538.377 379.503 538.339 379.769 538.031C386.801 529.866 408.31 502.394 394.243 487.097L366.402 486.247C365.843 486.801 365.003 487.752 364.171 489.149L364.169 489.15Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_20"
                d="M393.365 465.201C398.563 472.625 398.43 481.431 398.43 481.431C398.43 481.431 392.019 484.954 378.725 484.754C365.431 484.553 359.129 480.837 359.129 480.837C359.129 480.837 359.262 472.031 364.681 464.768"
                fill="#E2E8E8"
              />
              <path
                id="Vector_21"
                d="M400.978 482.951C400.712 483.397 400.328 483.774 399.857 484.032C399.567 484.192 392.597 487.933 378.677 487.722C364.759 487.511 357.904 483.563 357.619 483.395C356.7 482.853 356.143 481.859 356.159 480.793C356.166 480.393 356.378 470.94 362.3 462.993C363.28 461.679 365.139 461.408 366.454 462.388C367.769 463.369 368.036 465.241 367.059 466.543C363.715 471.029 362.62 476.275 362.264 479.013C364.783 480.001 370.239 481.659 378.768 481.788C387.29 481.916 392.793 480.428 395.349 479.513C395.077 476.768 394.141 471.488 390.934 466.907C389.994 465.563 390.321 463.711 391.662 462.772C393.006 461.832 394.857 462.159 395.797 463.501C401.48 471.619 401.404 481.079 401.398 481.478C401.39 482.007 401.24 482.513 400.979 482.951L400.978 482.951Z"
                fill="#000072"
              />
              <path
                id="Vector_22"
                d="M404.103 460.121C400.545 474.95 383.011 474.248 378.885 474.187C374.759 474.125 357.212 474.296 354.097 459.366C350.954 444.268 356.912 379.778 380.306 380.132C403.702 380.485 407.706 445.126 404.102 460.123L404.103 460.121Z"
                fill="#E81818"
              />
              <g id="Group_9" opacity="0.7">
                <path
                  id="Vector_23"
                  d="M375.001 474.045C369.042 472.495 362.651 468.879 360.893 460.432C357.828 445.714 363.415 384.08 385.359 381.295C383.785 380.561 382.1 380.153 380.308 380.127C356.912 379.775 350.954 444.266 354.096 459.366C356.584 471.299 368.298 473.579 375.001 474.044L375.001 474.045Z"
                  fill="#F2F2F2"
                />
              </g>
              <g id="Group_10" opacity="0.3">
                <path
                  id="Vector_24"
                  d="M398.214 459.896C395.408 471.591 383.916 473.624 377.156 473.933C379.243 471.936 380.914 469.277 381.764 465.712C384.879 452.776 382.323 402.893 366.543 389.211C369.624 384.506 373.305 381.282 377.648 380.388C398.18 386.344 401.648 445.623 398.215 459.897L398.214 459.896Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_25"
                d="M404.69 466.551C401.24 472.343 394.003 477.422 379.176 477.16L378.505 477.15C358.841 476.902 352.8 467.672 351.192 459.972C348.358 446.357 352.094 401.73 366.307 384.526C370.408 379.562 375.132 377.084 380.352 377.164C385.572 377.241 390.221 379.862 394.17 384.953C407.866 402.603 410.237 447.301 406.99 460.816C406.54 462.698 405.816 464.663 404.69 466.554L404.69 466.551ZM368.232 392.118C357.685 409.824 354.675 447.57 357.004 458.761C358.719 466.978 365.776 471.053 378.58 471.214L379.281 471.225C392.083 471.452 399.258 467.59 401.217 459.43C404.099 447.439 401.702 404.342 389.479 388.593C386.65 384.949 383.635 383.153 380.26 383.102C376.886 383.052 373.818 384.756 370.882 388.31C369.949 389.439 369.065 390.716 368.228 392.121L368.232 392.118Z"
                fill="#000072"
              />
              <path
                id="Vector_26"
                d="M406.889 437.505C406.614 437.967 406.171 438.332 405.615 438.498C388.403 443.645 370.294 443.371 353.245 437.707C353.093 437.656 352.946 437.589 352.808 437.507C351.753 436.878 351.405 435.512 352.035 434.455C352.591 433.521 353.724 433.142 354.707 433.501C370.867 438.857 388.027 439.109 404.34 434.232C405.518 433.879 406.758 434.549 407.11 435.726C407.295 436.349 407.198 436.987 406.89 437.504L406.889 437.505Z"
                fill="#000072"
              />
            </g>
            <path
              id="Vector_27"
              d="M411.38 465.48C421.649 467.597 432.114 468.464 442.548 468.076C452.589 467.713 462.6 466.186 472.38 463.5C480.098 461.373 486.031 455.184 487.818 447.387L491.776 430.138L442.646 429.952L383.04 429.735L386.868 447.012C387.736 450.916 389.639 454.429 392.285 457.236C394.938 460.044 398.333 462.148 402.185 463.234C405.232 464.093 408.294 464.845 411.38 465.48V465.48Z"
              fill="#E2E8E8"
            />
            <path
              id="Vector_28"
              d="M447.562 51.0497C459.97 60.7326 483.402 82.2849 503.292 119.214C519.636 149.567 533.593 190.324 537.052 243.436C539.294 277.884 536.819 307.58 532.059 332.509C519.657 397.537 491.766 430.138 491.766 430.138C490.85 430.476 489.919 430.806 488.996 431.116C471.292 437.237 452.765 440.071 434.295 439.642C424.181 439.408 414.097 438.189 404.164 436C403.887 435.941 403.619 435.876 403.349 435.812C396.471 434.256 389.694 432.23 383.039 429.738C383.039 429.738 373.367 418.247 362.988 395.365C361.283 391.619 359.575 387.576 357.877 383.22C355.868 378.058 353.881 372.455 352 366.438C351.819 365.868 351.645 365.297 351.465 364.721C349.096 357.007 346.893 348.612 344.991 339.536C344.475 337.035 343.967 334.477 343.493 331.868C338.92 306.901 336.654 277.172 339.147 242.708C339.308 240.563 339.476 238.424 339.683 236.314C340.481 227.311 341.59 218.677 342.964 210.398C344.86 198.912 347.259 188.117 350.051 177.989C354.438 162.043 359.779 147.747 365.652 134.978C366.81 132.456 367.989 129.996 369.193 127.59C390.246 85.33 416.616 61.3014 430.056 50.9698C435.221 46.9964 442.419 47.0379 447.559 51.0517L447.562 51.0497Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_29"
              d="M359.558 149.346C380.44 144.963 401.594 142.373 422.803 141.575C454.456 140.375 486.214 143.16 517.324 149.93C495.83 93.8272 463.082 63.1628 447.571 51.0452C442.43 47.0343 435.223 47.0067 430.049 50.9788C429.656 51.2794 429.249 51.593 428.835 51.9214C412.792 64.5309 380.91 94.8121 359.558 149.346V149.346Z"
              fill="#E81818"
            />
            <g id="Group_11" opacity="0.3">
              <path
                id="Vector_30"
                d="M452.581 61.5388L452.584 61.5463C465.125 73.5034 481.229 92.0852 495.509 118.598C511.847 148.952 525.809 189.706 529.267 242.82C531.51 277.27 529.033 306.96 524.273 331.89C511.874 396.918 483.982 429.521 483.982 429.521C483.982 429.521 483.98 429.522 483.978 429.523L483.989 429.522L480.031 446.77C478.244 454.568 472.311 460.757 464.593 462.884C457.035 464.96 449.34 466.335 441.6 467.024L441.671 439.412C440.777 439.439 439.883 439.467 438.989 439.478L433.968 396.776C433.968 396.776 456.271 392.092 465.133 422.037C473.914 401.765 482.75 375.704 488.821 343.859C492.273 325.785 494.818 305.829 495.973 284.031C484.11 298.212 465.036 305.584 445.661 301.591C417.594 295.804 399.531 268.36 405.317 240.293C411.104 212.227 438.548 194.163 466.614 199.95C478.037 202.305 487.801 208.247 494.977 216.358C489.736 151.266 472.714 100.355 452.579 61.5393L452.581 61.5388Z"
                fill="#000072"
              />
            </g>
            <path
              id="Vector_31"
              d="M519.875 151.445C519.788 151.591 519.689 151.729 519.577 151.86C518.866 152.687 517.759 153.058 516.694 152.826C464.809 141.537 412.145 141.341 360.166 152.246C359.099 152.47 357.995 152.091 357.291 151.258C356.586 150.425 356.395 149.273 356.792 148.26C378.362 93.1764 410.364 62.5437 427.521 49.1845C434.192 43.9902 443.481 44.0251 450.113 49.2694C467.165 62.7549 498.935 93.6257 520.096 148.864C520.424 149.721 520.334 150.671 519.873 151.444L519.875 151.445ZM364.37 145.34C413.6 135.74 463.393 135.924 512.541 145.889C491.938 95.1704 462.477 66.6158 446.43 53.9266C441.951 50.384 435.675 50.3608 431.168 53.8689C415.026 66.4382 385.349 94.7769 364.369 145.341L364.37 145.34Z"
              fill="#000072"
            />
            <path
              id="Vector_32"
              d="M504.529 416.73C498.635 426.625 494.34 431.709 494.026 432.072C493.695 432.459 493.271 432.755 492.792 432.931C474.975 439.456 456.322 442.73 437.351 442.66C418.379 442.589 399.753 439.178 381.993 432.521C381.515 432.342 381.094 432.043 380.764 431.654C380.21 430.997 367.081 415.216 354.946 383.887C343.798 355.111 331.497 307.222 336.196 242.498C338.658 208.567 345.587 176.861 356.794 148.26C357.157 147.329 357.969 146.644 358.947 146.438C411.737 135.363 465.218 135.558 517.913 147.017C518.898 147.215 519.738 147.903 520.107 148.864C531.1 177.546 537.795 209.302 540.006 243.251C544.225 308.009 531.566 355.806 520.204 384.498C514.765 398.233 509.154 408.962 504.528 416.729L504.529 416.73ZM489.988 427.633C492.454 424.541 504.086 409.177 514.802 382.016C525.881 353.935 538.219 307.131 534.082 243.638C531.948 210.878 525.575 180.222 515.134 152.492C464.275 141.648 412.688 141.456 361.739 151.921C351.094 179.574 344.492 210.185 342.115 242.927C337.508 306.39 349.498 353.283 360.366 381.444C370.878 408.684 382.393 424.132 384.836 427.245C401.704 433.466 419.376 436.656 437.372 436.722C455.368 436.789 473.064 433.73 489.988 427.633Z"
              fill="#000072"
            />
            <path
              id="Vector_33"
              d="M487.851 455.182C484.634 460.583 479.424 464.638 473.166 466.359C449.66 472.823 424.835 472.73 401.377 466.092C392.611 463.612 385.941 456.546 383.968 447.653L380.138 430.38C379.783 428.78 380.793 427.193 382.393 426.839C383.992 426.485 385.579 427.494 385.933 429.094L389.763 446.368C391.262 453.127 396.33 458.496 402.992 460.381C425.407 466.723 449.129 466.812 471.591 460.636C478.266 458.801 483.375 453.469 484.922 446.721L488.88 429.476C489.247 427.88 490.838 426.881 492.437 427.248C494.034 427.615 495.032 429.207 494.665 430.804L490.707 448.049C490.121 450.603 489.147 453.003 487.849 455.182L487.851 455.182Z"
              fill="#000072"
            />
            <g id="Group_12" opacity="0.3">
              <path
                id="Vector_34"
                d="M430.517 69.7882C417.065 77.3347 392.221 111.205 400.164 122.32C407.719 132.894 443.968 62.2408 430.517 69.7882Z"
                fill="#F2F2F2"
              />
            </g>
            <g id="Group_13">
              <path
                id="Vector_35"
                d="M485.465 277.872C499.928 253.592 491.969 222.184 467.688 207.721C443.408 193.258 412 201.217 397.537 225.497C383.074 249.778 391.033 281.185 415.313 295.648C439.594 310.111 471.002 302.153 485.465 277.872Z"
                fill="#E81818"
              />
              <g id="Group_14" opacity="0.7">
                <path
                  id="Vector_36"
                  d="M428.859 301.323C430.332 301.627 431.808 301.866 433.281 302.041C408.106 294.537 392.426 268.885 397.827 242.685C403.229 216.476 427.778 199.117 453.867 202.182C452.447 201.76 450.996 201.396 449.522 201.093C421.84 195.386 394.776 213.198 389.069 240.879C383.364 268.552 401.176 295.615 428.859 301.323Z"
                  fill="#F2F2F2"
                />
              </g>
              <g id="Group_15" opacity="0.25">
                <path
                  id="Vector_37"
                  d="M431.168 301.799C458.85 307.506 485.915 289.695 491.618 262.021C497.325 234.339 479.514 207.275 451.832 201.568C450.288 201.25 448.757 201.008 447.221 200.831C472.28 208.402 487.869 233.998 482.48 260.137C477.092 286.274 452.652 303.611 426.641 300.653C428.123 301.097 429.624 301.481 431.168 301.799V301.799Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_38"
                d="M487.556 279.167C483.466 286.034 477.842 291.939 470.971 296.461C459.01 304.332 444.701 307.075 430.677 304.184C416.654 301.293 404.596 293.115 396.725 281.154C388.854 269.193 386.111 254.884 389.001 240.862C394.969 211.914 423.376 193.218 452.324 199.184C481.273 205.152 499.969 233.56 494 262.506C492.771 268.473 490.583 274.082 487.555 279.165L487.556 279.167ZM399.602 226.777C396.895 231.321 394.891 236.379 393.766 241.842C391.137 254.593 393.63 267.604 400.789 278.478C407.946 289.354 418.91 296.789 431.66 299.419C444.411 302.047 457.422 299.554 468.296 292.396C479.17 285.237 486.607 274.275 489.237 261.524C494.663 235.203 477.664 209.374 451.343 203.946C430.485 199.646 409.934 209.43 399.603 226.774L399.602 226.777Z"
                fill="#000072"
              />
              <path
                id="Vector_39"
                d="M435.057 282.961C445.412 285.096 455.632 281.949 462.918 275.367C466.996 271.694 470.147 266.952 471.91 261.451C472.252 260.374 472.545 259.27 472.779 258.134C473.144 256.369 473.358 254.608 473.413 252.863C473.641 246.953 472.21 241.247 469.469 236.279C465.121 228.39 457.473 222.373 447.952 220.412C438.432 218.448 429.019 220.948 421.904 226.473C417.431 229.954 413.859 234.628 411.732 240.146C411.092 241.772 410.593 243.472 410.228 245.238C409.994 246.373 409.825 247.504 409.714 248.627C409.158 254.377 410.177 259.979 412.46 264.963C416.548 273.891 424.692 280.823 435.055 282.958L435.057 282.961Z"
                fill="#22A7F0"
              />
              <g id="Group_16">
                <path
                  id="Vector_40"
                  opacity="0.7"
                  d="M412.993 264.444L465.598 229.821C461.581 225.481 456.534 222.458 451.084 220.883L410.518 247.583C409.816 253.208 410.597 259.038 412.994 264.445L412.993 264.444Z"
                  fill="url(#paint2_linear_233_93)"
                />
              </g>
              <g id="Group_17">
                <path
                  id="Vector_41"
                  opacity="0.7"
                  d="M431.058 281.478L474.092 253.156C474.379 247.674 473.253 242.094 470.61 236.957L417.565 271.868C421.237 276.326 425.919 279.569 431.058 281.478V281.478Z"
                  fill="url(#paint3_linear_233_93)"
                />
              </g>
              <path
                id="Vector_42"
                d="M471.042 269.247C463.757 281.476 449.269 288.374 434.561 285.342C416.002 281.516 404.015 263.304 407.842 244.746C409.696 235.755 414.939 228.025 422.607 222.978C430.275 217.932 439.448 216.173 448.44 218.026C457.43 219.879 465.16 225.123 470.206 232.791C475.253 240.458 477.012 249.634 475.157 258.624C474.364 262.475 472.949 266.044 471.042 269.247V269.247ZM435.542 280.579C451.474 283.863 467.109 273.571 470.392 257.641C471.984 249.923 470.474 242.047 466.141 235.465C461.809 228.883 455.172 224.382 447.455 222.791C439.739 221.199 431.863 222.71 425.279 227.041C418.698 231.372 414.197 238.009 412.605 245.727C409.32 261.66 419.609 277.292 435.541 280.578L435.542 280.579Z"
                fill="#000072"
              />
            </g>
            <g id="Group_18">
              <g id="Group_19">
                <path
                  id="Vector_43"
                  d="M419.105 501.598C419.105 501.598 398.131 516.799 429.706 557.937C431.19 559.87 434.069 559.961 435.658 558.115C444.197 548.2 466.348 519.184 449.211 502.516L419.105 501.598V501.598Z"
                  fill="#E81818"
                />
                <path
                  id="Vector_44"
                  d="M423.46 501.642C423.46 501.642 408.576 512.428 431.079 541.636C432.065 542.916 433.976 542.975 435.032 541.754C440.968 534.893 456.951 514.161 444.746 502.292L423.46 501.642Z"
                  fill="#E7A900"
                />
                <path
                  id="Vector_45"
                  d="M426.966 500.617C426.966 500.617 416.924 507.895 432.108 527.605C432.773 528.467 434.063 528.507 434.776 527.683C438.782 523.054 449.565 509.066 441.331 501.056L426.969 500.617L426.966 500.617Z"
                  fill="#FFFD01"
                />
                <path
                  id="Vector_46"
                  d="M449.927 543.339C445.886 550.124 441.211 556.027 437.813 559.972C436.497 561.501 434.586 562.345 432.572 562.287C430.55 562.231 428.683 561.276 427.448 559.67C413.863 541.968 407.908 526.574 409.751 513.915C411.208 503.908 417.181 499.476 417.435 499.292C417.945 498.922 418.562 498.732 419.191 498.752L449.297 499.672C450.006 499.694 450.684 499.98 451.193 500.477C463.601 512.545 458.037 529.721 449.926 543.338L449.927 543.339ZM417.902 507.378C416.845 509.153 415.798 511.649 415.35 514.97C414.321 522.566 416.368 535.888 431.963 556.206C432.217 556.538 432.554 556.596 432.732 556.6C432.906 556.605 433.237 556.567 433.502 556.26C440.535 548.094 462.043 520.623 447.976 505.326L420.136 504.475C419.577 505.028 418.737 505.98 417.905 507.377L417.902 507.378Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_47"
                d="M447.098 483.428C452.295 490.852 452.163 499.658 452.163 499.658C452.163 499.658 445.752 503.181 432.457 502.981C419.163 502.78 412.862 499.064 412.862 499.064C412.862 499.064 412.994 490.258 418.414 482.995"
                fill="#E2E8E8"
              />
              <path
                id="Vector_48"
                d="M454.712 501.176C454.446 501.623 454.062 501.999 453.591 502.258C453.301 502.417 446.331 506.158 432.412 505.947C418.493 505.737 411.638 501.788 411.353 501.62C410.434 501.079 409.877 500.085 409.893 499.019C409.899 498.62 410.112 489.16 416.034 481.218C417.014 479.905 418.873 479.633 420.189 480.614C421.504 481.595 421.769 483.468 420.793 484.768C417.45 489.254 416.353 494.5 415.998 497.239C418.518 498.227 423.973 499.885 432.502 500.013C441.024 500.142 446.527 498.653 449.083 497.738C448.811 494.994 447.875 489.714 444.668 485.132C443.728 483.789 444.055 481.938 445.397 480.998C446.74 480.058 448.591 480.384 449.531 481.726C455.214 489.845 455.138 499.305 455.132 499.704C455.124 500.232 454.974 500.739 454.713 501.177L454.712 501.176Z"
                fill="#000072"
              />
              <path
                id="Vector_49"
                d="M457.836 478.348C454.277 493.177 436.743 492.475 432.617 492.414C428.491 492.352 410.944 492.523 407.829 477.593C404.686 462.495 410.644 398.005 434.039 398.359C457.434 398.712 461.439 463.353 457.835 478.35L457.836 478.348Z"
                fill="#E81818"
              />
              <g id="Group_20" opacity="0.7">
                <path
                  id="Vector_50"
                  d="M428.733 492.272C422.775 490.723 416.384 487.106 414.625 478.659C411.561 463.941 417.147 402.308 439.091 399.523C437.519 398.787 435.833 398.38 434.04 398.354C410.644 398.002 404.686 462.493 407.829 477.593C410.317 489.527 422.03 491.808 428.734 492.271L428.733 492.272Z"
                  fill="#F2F2F2"
                />
              </g>
              <g id="Group_21" opacity="0.3">
                <path
                  id="Vector_51"
                  d="M451.948 478.123C449.143 489.818 437.65 491.852 430.89 492.161C432.977 490.163 434.648 487.505 435.499 483.94C438.613 471.003 436.058 421.12 420.277 407.438C423.359 402.734 427.04 399.51 431.382 398.615C451.915 404.571 455.382 463.851 451.95 478.124L451.948 478.123Z"
                  fill="#000072"
                />
              </g>
              <path
                id="Vector_52"
                d="M458.423 484.778C454.972 490.57 447.735 495.649 432.909 495.387L432.238 495.377C412.575 495.13 406.532 485.901 404.924 478.2C402.091 464.584 405.817 419.974 420.04 402.753C424.141 397.789 428.865 395.311 434.084 395.391C439.305 395.468 443.953 398.089 447.903 403.178C461.598 420.828 463.969 465.526 460.722 479.041C460.272 480.922 459.549 482.888 458.423 484.778ZM421.964 410.346C411.417 428.053 408.408 465.797 410.736 476.989C412.451 485.207 419.509 489.28 432.313 489.441L433.014 489.453C445.816 489.679 452.991 485.819 454.951 477.656C457.832 465.665 455.435 422.568 443.212 406.819C440.384 403.174 437.369 401.379 433.993 401.328C430.62 401.277 427.553 402.98 424.616 406.534C423.684 407.663 422.798 408.942 421.962 410.347L421.964 410.346Z"
                fill="#000072"
              />
              <path
                id="Vector_53"
                d="M459.348 456.725C442.136 461.872 424.027 461.598 406.979 455.934C406.827 455.883 406.679 455.816 406.542 455.734C405.486 455.105 405.139 453.739 405.768 452.682C406.324 451.749 407.455 451.37 408.439 451.728C424.598 457.085 441.761 457.338 458.074 452.459L459.348 456.725V456.725Z"
                fill="#000072"
              />
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_233_93"
            x1="222.597"
            y1="869.257"
            x2="641.156"
            y2="487.629"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CFCFCF" />
            <stop offset="1" stopColor="#ADADAD" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_233_93"
            x1="225.231"
            y1="632.727"
            x2="677.559"
            y2="601.929"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#E0E0E0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_233_93"
            x1="423.474"
            y1="248.373"
            x2="449.37"
            y2="231.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_233_93"
            x1="436.693"
            y1="268.569"
            x2="462.805"
            y2="251.253"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <clipPath id="clip0_233_93">
            <rect width="1000.47" height="1000.47" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {/***********
       ** Footer **
       **********/}
      <footer id="footer">
        {/* Secondary nav */}
        <nav id="footer-navbar" aria-label="Secondary navigation">
          <ul className="footer-links">
            <li className="footer-navbar-link">Projects</li>
            <li className="footer-navbar-link">Skills</li>
            <li className="footer-navbar-link">About Me</li>
          </ul>
        </nav>
        {/* Copyright */}
        <p className="copyright">© Copyright 2022 | VictorMtzCodes</p>
      </footer>
    </section>
  );
};

export default Contact;
