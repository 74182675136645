import "./Hero.css";
import { useEffect, useRef } from "react";
import gsap from "gsap";

const Hero = ({ reducedMotion }) => {
  {
    /*******************************
     **  Refs for load animation  **
     ******************************/
  }
  let subtitleRef = useRef(null);
  let heroPRef = useRef(null);
  let heroBtns = useRef(null);

  useEffect(() => {
    // Only play animations if user has reduced motion off
    if (!reducedMotion) {
      {
        /**************************
         **  Subtitle animation  **
         *************************/
      }
      gsap.fromTo(
        subtitleRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, delay: 0.2 }
      );
      {
        /**************************
         **   P tag animation    **
         *************************/
      }
      gsap.fromTo(
        heroPRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1, delay: 0.3 }
      );
      {
        /**************************
         **  Buttons animation  **
         *************************/
      }
      gsap.fromTo(
        heroBtns.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1, delay: 0.5 }
      );
    }
  }, []);

  return (
    <section className="section" id="hero-section">
      <div className="hero">
        {/****************
         **  Main Text  **
         ****************/}
        <h1 className="title">Victor.</h1>
        <h2 className="subtitle" ref={subtitleRef}>
          Front-end <b>Developer</b>
        </h2>
        <p className="hero-p" ref={heroPRef}>
          Discover the secrets of a successful website
        </p>
        {/****************
         **  Buttons  **
         ****************/}
        <div className="hero-btns" ref={heroBtns}>
          <a
            href="#projects-section"
            aria-label="Continue to projects section"
            className="main-btn focus-link"
          >
            Continue
          </a>
          <a
            href="#contact"
            aria-label="Contact me"
            className="main-btn focus-link secondary-btn"
          >
            Contact
          </a>
        </div>
        {/*******************************
         ** Planets & Astronaut images **
         ******************************/}
        <img
          src="./assets/planet-green.svg"
          alt="Green planet with ring"
          className="planet planet-green"
          aria-hidden="true"
        />
        <img
          src="./assets/planet-earth.svg"
          alt="Planet earth"
          className="planet planet-earth"
          aria-hidden="true"
        />
        <img
          src="./assets/planet-pink.svg"
          alt="Pink planet with a ring"
          className="planet planet-pink"
          aria-hidden="true"
        />
        {/**************************
         **     Astronaut SVG     **
         ***************************/}
        <svg
          width="400"
          height="400"
          viewBox="0 0 4182 6997"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="hero-astronaut"
          aria-hidden="true"
        >
          <g>
            <g id="planet-orange">
              <path
                id="Vector"
                d="M3506.23 4349.69C3352.32 3637.98 2677.24 3173.28 1968.26 3269.35C1966.74 3268.59 1964.84 3268.21 1963.69 3267.44C1938.93 3256.01 1915.69 3241.9 1894.74 3225.89C1881.02 3215.6 1868.07 3204.16 1852.45 3196.54C1811.31 3176.72 1764.83 3186.25 1724.83 3196.54C1695.87 3204.16 1666.54 3211.41 1637.59 3219.03C1608.63 3226.27 1569.01 3249.14 1547.68 3265.16C1505.77 3296.41 1515.68 3372.66 1483.68 3424.88C1482.15 3427.17 1481.01 3429.46 1479.48 3431.74C959.466 3720.32 666.503 4324.53 798.698 4935.99C827.27 5067.13 873.367 5190.26 933.941 5302.71C930.131 5324.82 924.417 5346.55 917.178 5367.14C911.464 5383.15 904.606 5398.78 901.94 5416.31C895.082 5461.3 917.94 5503.23 939.274 5538.3C954.894 5563.84 970.514 5589.38 986.514 5614.92C1002.13 5640.47 1035.66 5671.34 1057.37 5687.35C1099.66 5718.23 1169.38 5686.21 1228.81 5701.46C1245.57 5705.65 1260.81 5710.23 1274.91 5714.8C1590.35 5973.26 2016.27 6090.29 2446 5997.28C2844.87 5910.74 3165.64 5660.67 3354.22 5332.83C3365.65 5329.02 3377.46 5325.97 3389.27 5323.68C3406.03 5320.25 3422.79 5317.58 3439.18 5310.72C3481.08 5292.8 3504.32 5251.25 3522.99 5214.66C3536.32 5187.97 3550.04 5160.91 3563.37 5134.22C3576.7 5107.54 3585.47 5062.55 3587.37 5035.87C3590.8 4988.6 3538.23 4948.95 3514.99 4898.25C3548.13 4721.75 3547.37 4536.1 3506.99 4349.69H3506.23Z"
                fill="url(#paint0_linear_225_334)"
              />
              <path
                id="Vector_2"
                opacity="0.5"
                d="M3223.93 4939.04C3216.69 4924.56 3075.35 4642.46 2521.05 4866.23C2355.71 4932.94 2068.84 4866.61 2132.46 4491.5C2156.46 4350.84 2205.99 4256.68 2211.7 4065.31C2215.13 3953.62 2121.03 3862.89 2009.41 3862.89C1929.03 3862.89 1883.31 3907.11 1882.55 3907.87C1729.78 4023.38 1524.82 4289.84 1330.91 3860.22C1329.76 3857.17 1320.62 3835.82 1314.91 3829.34C1297.38 3801.52 1269.95 3796.18 1235.67 3808.38C1235.67 3808.38 1131.28 3851.84 1062.71 3819.43C1048.23 3812.95 1034.9 3811.81 1032.61 3811.05C856.605 4043.58 751.839 4333.3 751.839 4647.42C751.839 5412.88 1372.05 6033.49 2137.03 6033.49C2509.62 6033.49 2847.54 5886.35 3096.31 5646.95C3242.22 5426.23 3325.65 5132.7 3223.93 4938.66V4939.04Z"
                fill="#FF4800"
              />
              <g id="Group">
                <path
                  id="Vector_3"
                  opacity="0.25"
                  d="M3011.35 3782.07C3279.55 4027.19 3383.55 4350.07 3244.12 4502.94C3104.31 4655.8 2774.01 4581.09 2505.81 4335.97C2237.61 4090.85 2133.6 3767.97 2273.04 3615.1C2412.47 3462.24 2743.15 3536.96 3011.35 3782.07V3782.07Z"
                  fill="white"
                />
                <g id="Group_2">
                  <path
                    id="Vector_4"
                    opacity="0.5"
                    d="M2883.35 3766.83C2962.21 3843.45 3003.73 3928.84 2975.92 3957.05C2948.11 3985.26 2862.01 3946.76 2783.15 3870.13C2704.29 3793.51 2662.77 3708.12 2690.58 3679.91C2718.39 3651.32 2804.49 3690.2 2883.35 3766.83Z"
                    fill="white"
                  />
                  <path
                    id="Vector_5"
                    opacity="0.5"
                    d="M3109.26 4001.27C3134.4 4025.67 3135.55 4065.31 3111.93 4089.71C3088.31 4114.11 3048.69 4114.11 3023.54 4089.71C2998.4 4065.31 2997.26 4025.67 3020.87 4001.27C3044.49 3976.87 3084.12 3976.87 3109.26 4001.27Z"
                    fill="white"
                  />
                </g>
              </g>
              <g id="Group_3">
                <path
                  id="Vector_6"
                  d="M2465.04 3741.67C2462 3743.57 2458.57 3744.34 2454.76 3743.57C2334.75 3719.94 2327.14 3597.95 2327.14 3596.81C2326.75 3589.18 2332.47 3582.7 2340.09 3582.32C2347.71 3581.94 2354.18 3587.66 2354.56 3595.28C2354.56 3599.48 2361.42 3697.07 2459.71 3716.51C2467.33 3718.03 2472.28 3725.27 2470.76 3732.9C2470 3736.71 2467.71 3739.76 2464.66 3742.05L2465.04 3741.67Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_7"
                  d="M1794.93 3745.48C1778.92 3755.77 1759.5 3763.78 1735.49 3767.59C1727.88 3768.73 1720.64 3763.78 1719.49 3756.15C1718.35 3748.53 1723.3 3741.29 1730.92 3740.14C1830.36 3723.37 1839.5 3622.73 1839.5 3621.97C1840.26 3614.34 1846.74 3608.63 1854.36 3609.39C1861.98 3610.15 1867.69 3616.63 1866.93 3624.25C1866.93 3625.4 1859.69 3703.93 1794.93 3745.48V3745.48Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_8"
                  d="M2354.56 3596.81C2357.61 3661.99 2244.85 3719.94 2102.37 3726.42C1960.26 3732.9 1842.55 3685.25 1839.5 3620.06C1836.45 3554.87 1949.6 3496.93 2091.7 3490.45C2233.8 3483.97 2351.52 3531.62 2354.56 3596.81Z"
                  fill="#AC3600"
                />
                <g id="Group_4">
                  <path
                    id="Vector_9"
                    d="M2106.21 3711.21C2235.08 3705.99 2337.68 3655.54 2335.37 3598.53C2333.07 3541.52 2226.72 3499.54 2097.85 3504.77C1968.98 3509.99 1866.38 3560.44 1868.69 3617.45C1871 3674.46 1977.34 3716.44 2106.21 3711.21Z"
                    fill="#C43906"
                  />
                  <path
                    id="Vector_10"
                    d="M2098.94 3537.72C2215.13 3533.15 2313.04 3566.69 2332.85 3615.11C2334.75 3609.77 2335.52 3604.05 2335.14 3598.33C2332.85 3541.15 2226.56 3499.22 2097.41 3504.56C1968.65 3509.89 1865.78 3560.21 1868.07 3617.39C1868.07 3623.11 1869.59 3628.45 1871.88 3633.79C1887.88 3583.85 1982.74 3542.3 2098.94 3537.72V3537.72Z"
                    fill="#7B2700"
                  />
                </g>
              </g>
              <g id="Group_5">
                <path
                  id="Vector_11"
                  d="M2113.41 5658.76C2115.7 5656.86 2118.37 5655.71 2121.41 5655.71C2222.75 5658.38 2245.99 5757.12 2246.37 5757.88C2247.89 5763.98 2243.7 5770.08 2237.61 5771.6C2231.51 5773.13 2225.42 5768.93 2223.89 5762.83C2223.13 5759.4 2204.08 5680.49 2121.03 5678.59C2114.56 5678.59 2109.6 5673.25 2109.98 5666.77C2109.98 5663.34 2111.51 5660.67 2113.79 5658.38L2113.41 5658.76Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_12"
                  d="M2660.86 5562.32C2672.67 5551.64 2687.53 5542.49 2706.2 5536.01C2712.29 5534.11 2718.77 5537.16 2720.67 5543.26C2722.96 5549.36 2719.53 5555.84 2713.44 5557.74C2634.58 5585.19 2641.05 5668.67 2641.05 5669.44C2641.43 5675.92 2636.86 5681.25 2630.77 5682.02C2624.29 5682.78 2618.96 5677.82 2618.19 5671.72C2618.19 5670.96 2612.86 5605.78 2660.48 5562.7L2660.86 5562.32Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_13"
                  d="M2223.89 5761.69C2212.46 5708.7 2296.66 5645.42 2412.09 5620.64C2527.52 5595.86 2630.38 5618.35 2641.81 5670.96C2653.24 5723.95 2569.05 5787.23 2453.62 5812.01C2338.18 5837.17 2235.32 5814.3 2223.89 5761.69Z"
                  fill="#AC3600"
                />
                <g id="Group_6">
                  <path
                    id="Vector_14"
                    d="M2410.95 5633.22C2515.71 5611.11 2608.67 5630.55 2618.19 5677.06C2628.1 5723.57 2551.14 5779.22 2446.38 5801.34C2341.61 5823.45 2248.66 5804 2239.13 5757.5C2229.23 5710.99 2306.18 5655.33 2410.95 5633.22Z"
                    fill="#C43906"
                  />
                  <path
                    id="Vector_15"
                    d="M2441.04 5774.27C2346.56 5794.47 2261.99 5780.37 2239.13 5743.39C2238.37 5747.97 2238.75 5752.92 2239.51 5757.5C2249.42 5804 2342.37 5823.45 2446.76 5801.34C2551.52 5779.23 2628.48 5723.57 2618.57 5677.06C2617.43 5672.49 2615.91 5668.29 2613.24 5664.1C2607.15 5707.18 2535.52 5754.45 2441.04 5774.27V5774.27Z"
                    fill="#7B2700"
                  />
                </g>
              </g>
              <g id="Group_7">
                <path
                  id="Vector_16"
                  d="M1282.53 4279.93C1282.91 4281.84 1282.53 4284.13 1281.76 4285.65C1248.62 4347.41 1180.43 4331.01 1179.66 4330.63C1175.47 4329.49 1172.81 4325.3 1173.95 4321.1C1175.09 4316.91 1179.28 4314.24 1183.47 4315.39C1185.76 4316.15 1240.62 4328.73 1267.67 4278.03C1269.57 4274.21 1274.53 4272.69 1278.33 4274.98C1280.24 4276.12 1281.76 4278.03 1282.14 4279.93H1282.53Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_17"
                  d="M1170.52 4648.56C1173.57 4659.23 1174.33 4671.05 1172.81 4684.78C1172.05 4688.97 1168.24 4692.02 1164.04 4691.64C1159.85 4690.88 1156.81 4687.06 1157.19 4682.87C1164.81 4625.31 1111.47 4603.58 1110.71 4603.2C1106.52 4601.67 1104.61 4597.1 1106.14 4592.9C1107.66 4588.71 1112.23 4586.81 1116.42 4588.33C1116.81 4588.33 1158.71 4605.87 1170.52 4648.56Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_18"
                  d="M1184.24 4316.15C1220.43 4325.68 1233 4397.34 1212.43 4476.64C1191.86 4555.93 1145.76 4612.35 1109.57 4602.82C1073.37 4593.29 1060.8 4521.62 1081.37 4442.33C1101.95 4363.04 1148.04 4306.62 1184.24 4316.15Z"
                  fill="#AC3600"
                />
                <g id="Group_8">
                  <path
                    id="Vector_19"
                    d="M1204.81 4472.06C1185.76 4543.73 1144.62 4594.81 1112.99 4586.42C1081.37 4578.04 1071.09 4513.23 1090.14 4441.56C1109.19 4369.9 1150.33 4318.82 1181.95 4327.2C1213.57 4335.59 1223.86 4400.39 1204.81 4472.06Z"
                    fill="#C43906"
                  />
                  <path
                    id="Vector_20"
                    d="M1108.42 4446.52C1125.57 4382.1 1160.62 4334.06 1190.71 4331.4C1188.05 4329.49 1185 4327.96 1181.95 4327.2C1150.33 4318.82 1109.19 4369.9 1090.14 4441.56C1071.09 4513.23 1081.37 4578.04 1112.99 4586.42C1116.04 4587.19 1119.47 4587.57 1122.52 4587.19C1097.76 4570.03 1091.28 4510.94 1108.42 4446.14V4446.52Z"
                    fill="#7B2700"
                  />
                </g>
              </g>
              <g id="Group_9">
                <path
                  id="Vector_21"
                  d="M1892.07 4879.57C1893.98 4879.57 1896.26 4879.96 1897.79 4881.48C1951.88 4927.99 1920.26 4992.03 1920.26 4992.79C1918.36 4996.61 1913.41 4998.51 1909.6 4996.22C1905.79 4994.32 1903.88 4989.36 1906.17 4985.55C1907.31 4983.26 1932.07 4931.8 1887.88 4893.68C1884.45 4890.63 1884.07 4885.67 1887.12 4882.24C1888.64 4880.34 1890.55 4879.57 1892.83 4879.57H1892.07Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_22"
                  d="M2233.04 5071.32C2244.08 5070.94 2256.28 5072.47 2269.61 5077.04C2273.8 5078.56 2276.09 5083.14 2274.56 5087.33C2273.04 5091.53 2268.47 5093.81 2264.28 5092.29C2209.04 5072.08 2175.51 5120.5 2175.13 5120.88C2172.46 5124.69 2167.51 5125.45 2164.08 5123.17C2160.27 5120.5 2159.51 5115.54 2161.8 5112.11C2161.8 5111.73 2188.46 5073.99 2233.42 5071.7L2233.04 5071.32Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_23"
                  d="M1906.55 4985.17C1923.69 4951.24 1997.98 4954.29 2071.89 4992.41C2145.8 5030.15 2191.51 5088.48 2174.37 5122.4C2157.22 5156.33 2082.94 5153.28 2009.03 5115.16C1935.12 5077.04 1889.41 5019.1 1906.55 4985.17V4985.17Z"
                  fill="#AC3600"
                />
                <g id="Group_10">
                  <path
                    id="Vector_24"
                    d="M2065.41 4998.89C2132.46 5033.58 2173.99 5085.43 2158.75 5115.16C2143.51 5144.9 2076.84 5140.7 2009.79 5106.01C1942.74 5071.32 1901.21 5019.48 1916.45 4989.74C1931.69 4960.01 1998.36 4964.2 2065.41 4998.89V4998.89Z"
                    fill="#C43906"
                  />
                  <path
                    id="Vector_25"
                    d="M2018.93 5088.86C1958.74 5057.6 1918.74 5012.24 1922.55 4982.12C1920.26 4984.41 1917.98 4987.08 1916.45 4989.74C1901.21 5019.48 1943.12 5071.32 2009.79 5106.01C2076.84 5140.7 2143.13 5144.51 2158.75 5115.16C2160.27 5112.11 2161.03 5109.06 2161.8 5105.63C2139.32 5126.22 2079.13 5120.12 2018.93 5088.86V5088.86Z"
                    fill="#7B2700"
                  />
                </g>
              </g>
              <g id="Group_11">
                <path
                  id="Vector_26"
                  d="M3231.93 4820.49C3231.93 4820.49 3231.93 4817.06 3232.69 4815.53C3264.69 4762.16 3329.46 4777.41 3330.22 4777.79C3334.41 4778.93 3336.7 4782.75 3335.55 4786.18C3334.41 4789.99 3330.22 4791.9 3326.41 4791.13C3324.12 4790.75 3272.31 4778.55 3246.03 4822.39C3244.12 4825.82 3239.55 4826.97 3235.74 4825.06C3233.83 4823.92 3232.69 4822.39 3231.93 4820.87V4820.49Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_27"
                  d="M3339.74 4501.41C3337.08 4492.27 3335.93 4481.59 3337.84 4469.77C3338.22 4465.96 3342.41 4463.29 3346.22 4464.06C3350.41 4464.44 3353.08 4468.25 3352.7 4472.06C3345.08 4522 3396.13 4541.82 3396.51 4541.82C3400.32 4543.35 3402.22 4547.16 3400.7 4550.97C3399.17 4554.4 3394.6 4556.31 3390.79 4554.78C3390.41 4554.78 3350.41 4539.15 3339.36 4501.8L3339.74 4501.41Z"
                  fill="#C43906"
                />
                <path
                  id="Vector_28"
                  d="M3325.65 4790.37C3290.98 4781.6 3279.17 4719.08 3299.36 4650.47C3319.55 4581.85 3363.36 4533.44 3398.03 4542.2C3432.7 4550.97 3444.51 4613.49 3424.32 4682.11C3404.13 4750.72 3360.32 4799.14 3325.65 4790.37Z"
                  fill="#AC3600"
                />
                <g id="Group_12">
                  <path
                    id="Vector_29"
                    d="M3306.22 4654.66C3324.5 4592.52 3364.13 4548.68 3394.22 4556.31C3424.32 4563.93 3433.84 4620.73 3415.56 4682.87C3397.27 4745.01 3357.65 4788.84 3327.55 4781.22C3297.46 4773.6 3287.93 4716.8 3306.22 4654.66Z"
                    fill="#C43906"
                  />
                  <path
                    id="Vector_30"
                    d="M3398.03 4678.3C3381.27 4734.33 3347.74 4775.5 3319.17 4777.41C3321.84 4778.93 3324.5 4780.46 3327.55 4781.22C3357.65 4788.85 3397.27 4745.01 3415.56 4682.87C3433.84 4620.73 3424.32 4564.31 3394.22 4556.31C3391.17 4555.55 3388.13 4555.16 3385.08 4555.55C3408.7 4570.79 3414.79 4622.26 3398.03 4678.3V4678.3Z"
                    fill="#7B2700"
                  />
                </g>
              </g>
              <path
                id="Vector_31"
                d="M3054.02 4469.39C3048.3 4482.35 3046.78 4494.17 3027.73 4495.32C3007.92 4496.46 2996.11 4487.69 2994.97 4470.92C2993.83 4454.91 3004.11 4445.76 3023.16 4445.76C3039.16 4445.76 3055.16 4453.76 3054.02 4469.39Z"
                fill="#C43906"
              />
              <path
                id="Vector_32"
                d="M1547.3 3082.56C1539.68 3103.52 1537.39 3122.2 1511.1 3124.11C1484.06 3126.01 1467.67 3111.91 1466.15 3084.84C1464.63 3059.3 1478.34 3044.44 1505.01 3044.44C1526.72 3044.44 1548.82 3057.4 1547.3 3082.56V3082.56Z"
                fill="#FFB300"
              />
              <path
                id="Vector_33"
                d="M804.793 3731.37C797.936 3749.67 796.412 3765.68 773.554 3767.21C750.315 3768.73 735.838 3756.91 734.695 3733.28C733.553 3711.17 745.362 3698.59 768.22 3698.59C786.888 3698.59 806.317 3710.03 804.793 3731.37Z"
                fill="#FFB300"
              />
              <path
                id="Vector_34"
                d="M638.692 4812.86C634.12 4825.44 632.977 4836.5 617.358 4837.64C601.357 4838.78 591.452 4830.4 590.69 4814.39C589.928 4799.52 597.928 4790.37 613.548 4790.37C626.501 4790.37 639.454 4798 638.692 4812.86Z"
                fill="#FFB300"
              />
              <path
                id="Vector_35"
                d="M2655.15 3055.49C2650.58 3068.07 2649.43 3079.13 2633.81 3080.27C2617.81 3081.41 2607.91 3073.03 2607.15 3057.02C2606.38 3042.15 2614.38 3033 2630 3033C2642.96 3033 2655.91 3040.62 2655.15 3055.49V3055.49Z"
                fill="#FFB300"
              />
              <path
                id="Vector_36"
                d="M2101.22 4110.3C2091.7 4135.84 2089.41 4157.95 2057.79 4160.23C2025.03 4162.52 2005.22 4145.75 2003.31 4112.96C2001.79 4082.47 2018.17 4064.55 2050.17 4064.55C2076.46 4064.55 2103.13 4080.18 2101.22 4110.3Z"
                fill="#A93500"
              />
              <path
                id="Vector_37"
                d="M1547.3 5305.76C1539.68 5326.73 1537.39 5345.41 1511.1 5347.32C1484.06 5349.22 1467.67 5335.12 1466.15 5308.05C1464.63 5282.51 1478.34 5267.64 1505.01 5267.64C1526.72 5267.64 1548.82 5280.6 1547.3 5305.76Z"
                fill="#C43906"
              />
              <path
                id="Vector_38"
                d="M1646.73 4569.27C1642.16 4581.85 1641.01 4592.91 1625.4 4594.05C1609.39 4595.19 1599.49 4586.81 1598.73 4570.8C1597.97 4555.93 1605.97 4546.78 1621.59 4546.78C1634.54 4546.78 1647.49 4554.4 1646.73 4569.27V4569.27Z"
                fill="#C43906"
              />
              <path
                id="Vector_39"
                d="M2084.46 4102.67C2076.84 4123.64 2074.55 4142.32 2048.27 4144.22C2021.22 4146.13 2004.84 4132.02 2003.31 4104.96C2001.79 4079.42 2015.5 4064.55 2042.17 4064.55C2063.89 4064.55 2085.98 4077.51 2084.46 4102.67Z"
                fill="#C43906"
              />
              <path
                id="Vector_40"
                d="M1137 3986.78C1131.28 4002.03 1129.76 4015.76 1110.71 4016.9C1090.9 4018.42 1079.09 4008.13 1077.95 3988.31C1076.8 3969.63 1087.09 3958.96 1106.14 3958.96C1122.14 3958.96 1138.14 3968.49 1137 3986.78V3986.78Z"
                fill="#C43906"
              />
              <path
                id="Vector_41"
                d="M2495.9 4688.59C2489.43 4705.74 2487.9 4720.99 2466.57 4722.52C2444.47 4724.04 2431.14 4712.6 2430 4690.49C2428.85 4669.91 2439.9 4657.71 2461.62 4657.71C2479.14 4657.71 2497.43 4668.38 2495.9 4688.59V4688.59Z"
                fill="#C43906"
              />
              <path
                id="Vector_42"
                d="M2921.06 3071.5C2913.44 3092.47 2911.16 3111.15 2884.87 3113.05C2857.82 3114.96 2841.44 3100.86 2839.92 3073.79C2838.39 3048.25 2852.11 3033.38 2878.77 3033.38C2900.49 3033.38 2922.59 3046.34 2921.06 3071.5Z"
                fill="#FFB300"
              />
              <path
                id="Vector_43"
                d="M2932.11 3227.04C2926.39 3242.28 2924.87 3256.01 2905.82 3257.15C2886.01 3258.68 2874.2 3248.38 2873.06 3228.56C2871.92 3209.88 2882.2 3199.21 2901.25 3199.21C2917.25 3199.21 2933.25 3208.74 2932.11 3227.04Z"
                fill="#FFB300"
              />
              <path
                id="Vector_44"
                d="M555.26 4512.85C547.641 4533.82 545.355 4552.5 519.069 4554.4C492.02 4556.31 475.257 4542.2 474.115 4515.14C472.591 4489.6 486.306 4474.73 512.973 4474.73C534.688 4474.73 556.784 4487.69 555.26 4512.85Z"
                fill="#FFB300"
              />
              <path
                id="Vector_45"
                d="M3331.36 4189.59C3324.89 4206.74 3323.36 4221.99 3302.03 4223.51C3279.93 4225.04 3266.6 4213.6 3265.46 4191.49C3264.31 4170.91 3275.36 4158.71 3297.08 4158.71C3314.6 4158.71 3332.89 4169.38 3331.36 4189.59V4189.59Z"
                fill="#C43906"
              />
              <path
                id="Vector_46"
                d="M3708.14 5126.6C3703.95 5137.65 3703.19 5147.18 3689.09 5148.33C3674.99 5149.47 3666.23 5141.84 3665.47 5127.74C3664.71 5114.4 3671.95 5106.77 3685.66 5106.77C3697.09 5106.77 3708.52 5113.64 3707.76 5126.6H3708.14Z"
                fill="#FFB300"
              />
              <path
                id="Vector_47"
                d="M2677.24 6134.89C2671.15 6151.67 2669.62 6166.53 2648.67 6168.06C2626.96 6169.58 2614 6158.53 2612.86 6136.8C2611.72 6116.59 2622.76 6104.78 2643.72 6104.78C2660.86 6104.78 2678.77 6115.07 2677.24 6135.27V6134.89Z"
                fill="#FFB300"
              />
              <path
                id="Vector_48"
                d="M3574.8 3862.13C3568.7 3878.9 3567.18 3893.77 3546.23 3895.29C3524.51 3896.82 3511.56 3885.76 3510.42 3864.04C3509.27 3843.83 3520.32 3832.01 3541.28 3832.01C3558.42 3832.01 3576.32 3842.31 3574.8 3862.51V3862.13Z"
                fill="#FFB300"
              />
              <path
                id="Vector_49"
                d="M3635.75 5422.03C3628.14 5443 3625.85 5461.68 3599.56 5463.58C3572.51 5465.49 3556.13 5451.39 3554.61 5424.32C3553.08 5398.78 3566.8 5383.91 3593.47 5383.91C3615.18 5383.91 3637.28 5396.87 3635.75 5422.03V5422.03Z"
                fill="#FFB300"
              />
              <path
                id="Vector_50"
                d="M2239.51 6281.28C2231.89 6302.24 2229.61 6320.92 2203.32 6322.83C2176.27 6324.74 2159.51 6310.63 2158.37 6283.56C2156.84 6258.02 2170.56 6243.16 2197.23 6243.16C2218.94 6243.16 2241.04 6256.12 2239.51 6281.28Z"
                fill="#FFB300"
              />
              <path
                id="Vector_51"
                d="M1890.55 6189.02C1885.6 6201.99 1884.45 6213.42 1868.45 6214.57C1851.69 6215.71 1841.78 6207.32 1840.64 6190.55C1839.88 6174.92 1848.26 6165.77 1864.64 6165.77C1877.98 6165.77 1891.69 6173.78 1890.55 6189.02V6189.02Z"
                fill="#FFB300"
              />
              <path
                id="Vector_52"
                d="M2943.16 5213.51C2933.63 5239.05 2931.35 5261.16 2899.73 5263.45C2866.96 5265.74 2847.15 5248.96 2845.25 5216.18C2843.72 5185.68 2860.11 5167.77 2892.11 5167.77C2918.39 5167.77 2945.06 5183.4 2943.16 5213.51V5213.51Z"
                fill="#C43906"
              />
            </g>
            <g id="astro">
              <g id="Group 2">
                <g id="Asset 8 1">
                  <g id="Layer_1-2">
                    <path
                      id="Vector_53"
                      d="M2882.25 2114.8C2882.25 2114.8 2786.14 2291.48 2621.07 2318.5C2456.46 2345.45 2311.21 2153.43 2311.21 2153.43C2311.21 2153.43 2281.7 2047.09 2264.38 1951.12C2250.07 1872.26 2244 1800.43 2269.21 1800.43C2306.78 1800.43 2354.46 1836.08 2401.72 1879.75C2476.42 1948.84 2550.03 2038 2580.78 2037.86C2617.46 2037.68 2774.14 1922.71 2774.14 1922.71L2882.26 2114.8H2882.25Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_54"
                      d="M2882.25 2114.8C2882.25 2114.8 2786.14 2291.48 2621.07 2318.5C2484.55 2340.83 2361.35 2212.59 2323.28 2168.17C2315.43 2159.04 2311.21 2153.43 2311.21 2153.43C2311.21 2153.43 2281.7 2047.09 2264.38 1951.12C2250.07 1872.26 2244 1800.43 2269.21 1800.43C2306.78 1800.43 2354.46 1836.08 2401.72 1879.75C2476.42 1948.84 2550.03 2038 2580.78 2037.86C2617.46 2037.68 2774.14 1922.71 2774.14 1922.71L2868.37 2090.16L2882.25 2114.8V2114.8Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_55"
                      d="M2882.25 2114.8C2882.25 2114.8 2786.14 2291.48 2621.07 2318.5C2484.55 2340.83 2361.35 2212.59 2323.28 2168.17L2379.02 2037.86C2379.02 2037.86 2443.29 2234.63 2573.73 2245.96C2682.13 2255.33 2797.13 2189.33 2779.2 2121.41C2771.81 2093.43 2821.2 2088.64 2868.35 2090.16L2882.23 2114.8H2882.25Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_56"
                      d="M2401.72 1879.75C2363.3 1909.65 2316.65 1934.07 2264.38 1951.12C2250.07 1872.26 2244 1800.43 2269.21 1800.43C2306.78 1800.43 2354.46 1836.08 2401.72 1879.75V1879.75Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_57"
                      d="M1972.95 1753.03L1901.94 1948.28C1827.95 1922.68 1765.85 1882.27 1723.13 1832.42C1851.45 1723.13 1972.95 1753.03 1972.95 1753.03Z"
                      fill="#D8D8D8"
                    />
                    <path
                      id="Vector_58"
                      d="M2339.65 1919.52C2268.96 1956.62 2181.76 1978.53 2087.42 1978.53C1966.81 1978.53 1857.91 1942.74 1780.22 1885.19C1796.69 1834.42 1808.94 1800.44 1808.94 1800.44C1808.94 1800.44 2230.11 1629.8 2251.98 1727.72L2339.64 1919.52H2339.65Z"
                      fill="#D8D8D8"
                    />
                    <path
                      id="Vector_59"
                      d="M2588.62 2335.32C2432.9 2335.32 2305.6 2169.56 2299.88 2162C2298.8 2160.57 2298 2158.95 2297.52 2157.22C2297.31 2156.45 2276.08 2079.66 2259.16 1998.56C2223.8 1829.06 2241.57 1805.7 2248.25 1796.9C2253.48 1790.02 2260.93 1786.23 2269.21 1786.22C2331.52 1786.22 2410.95 1866.79 2481.03 1937.87C2518.74 1976.11 2565.71 2023.71 2580.72 2023.65C2600.76 2023.57 2690.54 1966.44 2765.74 1911.27C2769.02 1908.86 2773.16 1907.98 2777.14 1908.84C2781.11 1909.7 2784.53 1912.21 2786.52 1915.76L2894.6 2107.85C2897 2112.11 2897.04 2117.3 2894.7 2121.6C2890.64 2129.06 2793.32 2304.68 2623.35 2332.5C2611.64 2334.42 2600.04 2335.31 2588.61 2335.31L2588.62 2335.32ZM2324.15 2146.93C2340.27 2167.21 2473.45 2328.2 2618.77 2304.48C2757.06 2281.83 2846.59 2146.44 2865.86 2114.68L2769.6 1943.59C2726.34 1974.75 2615.69 2051.92 2580.85 2052.07H2580.72C2554.97 2052.07 2520.12 2017.99 2460.8 1957.83C2398.42 1894.55 2320.9 1815.93 2270.43 1814.67C2267.34 1819.31 2256.78 1846.87 2286.26 1989.43C2301.33 2062.29 2320.19 2132.46 2324.14 2146.94L2324.15 2146.93Z"
                      fill="#371A45"
                    />
                    <g id="Group_13">
                      <path
                        id="Vector_60"
                        d="M2735.33 1966.03C2735.33 1966.03 2780.54 1851.19 2832.06 1839.24C2854.64 1834 2867.66 1851.19 2865.24 1873.39C2862.82 1895.63 2843.96 1921.65 2845.09 1926.12C2845.09 1926.12 2920.36 1943.67 2948.5 1906.69C2956.95 1895.59 2960.43 1862.06 2977.94 1851.19C3012.33 1829.83 3030.16 1868.08 3029.1 1898.97C3028.28 1922.81 3030.08 2053.41 2941.02 2109.95C2918.7 2124.12 2833.14 2116.89 2833.14 2116.89"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_61"
                        d="M2728.72 1963.43C2745.41 1918.23 2773.83 1851.93 2820.1 1829.77C2845.08 1817.9 2873.59 1830.09 2878.31 1858.27C2882.79 1882.18 2870.92 1904.04 2860.8 1924.32C2860.04 1925.89 2859.57 1926.91 2859.06 1928.2C2859.58 1926.46 2859.55 1924.38 2858.99 1922.62L2848.35 1912.17C2875.67 1917 2916.71 1920.73 2936.4 1897.93C2945.95 1879.47 2947.69 1855.08 2967.01 1840.35C2977.37 1832.84 2990.17 1828.28 3003.29 1830.22C3005.41 1830.82 3008.89 1831.55 3010.98 1832.34C3012.94 1833.34 3016.03 1834.74 3017.91 1835.89C3051.99 1860.89 3044.88 1905.62 3042.39 1942.36C3035.97 2010.3 3010.68 2084.03 2949.76 2122.28C2946.33 2124.29 2941.24 2126.8 2937.49 2127.73C2902.91 2136.68 2866.57 2133.32 2831.95 2131.05C2824.13 2130.4 2818.32 2123.54 2818.97 2115.73C2819.61 2107.89 2826.55 2102.08 2834.34 2102.75C2858.86 2104.2 2884.37 2104.82 2908.08 2102.71C2916.76 2101.71 2925.96 2100.93 2933.45 2096.86C2964.19 2076.65 2984.55 2045.3 2996.58 2010.32C3009.36 1973.36 3013.51 1933.17 3013.5 1893.8C3012.7 1881.29 3007.56 1854.48 2990.45 1861.82L2987.55 1863.35L2986.07 1864.09C2973.18 1875.13 2972.55 1894.07 2963.93 1909.99C2955.96 1924.54 2940.64 1934.12 2925.27 1939.26C2897.02 1947.6 2869.94 1945.66 2841.85 1940.1C2830.76 1937.81 2828.51 1925.4 2832.94 1916.47C2840.04 1900.42 2854.12 1880.14 2851.38 1863.23C2850.02 1856.67 2847.64 1852.33 2840.97 1851.54C2813.58 1852.02 2789.74 1888.17 2775.17 1909.24C2762.71 1928.06 2751.77 1948.44 2741.86 1968.83C2738.06 1976.67 2725.93 1971.78 2728.73 1963.45L2728.72 1963.43Z"
                        fill="#371A45"
                      />
                    </g>
                    <g id="Group_14">
                      <path
                        id="Vector_62"
                        d="M2966.99 2088.89C3036.82 2017.7 3027.88 1917.69 2981.31 1929.15C2945.11 1938.06 2963.73 1974.89 2932.36 2003.85"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_63"
                        d="M2966.99 2103.09C2963.4 2103.09 2959.81 2101.74 2957.04 2099.02C2951.44 2093.53 2951.36 2084.54 2956.85 2078.94C3001.98 2032.93 3011.32 1972.46 2998.14 1949.35C2993.88 1941.87 2989.18 1941.83 2984.7 1942.94C2973.71 1945.65 2971.88 1950.72 2967.75 1966.37C2964 1980.59 2959.33 1998.28 2941.99 2014.29C2936.23 2019.62 2927.23 2019.25 2921.92 2013.49C2916.6 2007.73 2916.96 1998.74 2922.72 1993.42C2933.97 1983.03 2937.04 1971.41 2940.29 1959.12C2944.63 1942.68 2950.03 1922.23 2977.92 1915.37C2996.31 1910.82 3013.12 1918.28 3022.82 1935.29C3043.1 1970.86 3031.4 2043.5 2977.12 2098.83C2974.34 2101.66 2970.66 2103.09 2966.98 2103.09H2966.99Z"
                        fill="#371A45"
                      />
                    </g>
                    <g id="Group_15">
                      <path
                        id="Vector_64"
                        d="M2824.58 2061.41C2851.37 2028.47 2902.35 2054.15 2906.36 2051.44C2910.37 2048.74 2894.44 1998.24 2920.27 1985.01C2938.05 1975.9 2966.54 1983.06 2974.44 2042.59C2980.59 2088.92 2941.27 2113.41 2920.14 2123.67C2896.85 2134.99 2824.93 2169.74 2763.15 2107.47"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_65"
                        d="M2819.07 2056.93C2837.22 2025.56 2880.27 2030.4 2905.18 2037.48C2902.12 2037.31 2897.1 2039.65 2894.86 2043.19C2893.82 2044.77 2893.46 2045.7 2893.03 2047.23C2892.7 2048.87 2892.84 2048.52 2892.79 2048.4C2892.75 2047.21 2892.42 2044.25 2892.1 2041.67C2890.03 2023.73 2887.38 2000.7 2899.74 1983.04C2909.99 1968.59 2929.71 1962.8 2946.48 1968.16C2975.1 1977.92 2985.22 2009.15 2989.41 2034.78C2993.08 2053.84 2990.11 2076.36 2979.46 2093.12C2965.78 2115.26 2944.21 2128.8 2921.58 2139.05C2867.69 2166.21 2794.3 2168.45 2758.11 2112.49C2753.88 2106.02 2761.87 2098.22 2768.19 2102.49C2803.21 2125.44 2840.29 2136.63 2880.64 2124.02C2927.65 2109.02 2973.86 2082.01 2954.71 2025.55C2950.86 2012.32 2944.12 1994.87 2929.86 1997.83C2923.88 1999.52 2922.4 2003.12 2920.86 2009.87C2918.51 2020.88 2920.88 2035.11 2921.29 2048.49C2921.38 2049.89 2920.86 2054.51 2919.66 2056.82C2917.82 2061.77 2911.4 2065.74 2907.07 2065.77C2903.18 2066.05 2901.02 2065.35 2898.12 2064.68C2875.11 2057.88 2848.98 2049.69 2830.07 2065.92C2824.14 2071.42 2814.89 2063.81 2819.05 2056.96L2819.07 2056.93Z"
                        fill="#371A45"
                      />
                    </g>
                    <g id="Group_16">
                      <path
                        id="Vector_66"
                        d="M1939.62 1711.08L1950.21 1803.99L1955.63 1851.31L2048.79 2667.36C2048.79 2667.36 1992.79 2670.56 1917.35 2668.8C1902.59 2668.45 1887.09 2667.94 1871.12 2667.15C1752.11 2661.42 1607.1 2641.79 1549.91 2583.12C1464.41 2495.39 1432.25 2050.96 1497.85 1871.48C1527.89 1789.2 1696.24 1746.9 1816.22 1726.88C1829.62 1724.62 1842.44 1722.66 1854.38 1720.97C1904.7 1713.76 1939.63 1711.07 1939.63 1711.07L1939.62 1711.08Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_67"
                        d="M1950.21 1803.99L1955.63 1851.31L2029.6 2499.27L2048.79 2667.37C2048.79 2667.37 2047.62 2667.43 2045.4 2667.54C2044.77 2667.56 2044.01 2667.6 2043.22 2667.64C2042.59 2667.66 2041.88 2667.69 2041.16 2667.72C2040.62 2667.77 2040.07 2667.77 2039.53 2667.82C2039.32 2667.82 2039.07 2667.85 2038.86 2667.84C2037.85 2667.88 2036.72 2667.93 2035.54 2667.96C2035.29 2668 2035.04 2668 2034.74 2668C2033.77 2668.03 2032.77 2668.07 2031.68 2668.12C2030.21 2668.19 2028.7 2668.22 2027.1 2668.27C2025.96 2668.29 2024.75 2668.36 2023.53 2668.39C2022.27 2668.43 2021.01 2668.46 2019.7 2668.51C2018.06 2668.57 2016.33 2668.59 2014.57 2668.67C2010.99 2668.75 2007.21 2668.85 2003.2 2668.91C1999.67 2669.02 1995.96 2669.07 1992.08 2669.11C1987.61 2669.16 1982.94 2669.25 1978.08 2669.27C1960.15 2669.4 1939.6 2669.32 1917.34 2668.8C1913.02 2668.7 1908.6 2668.57 1904.15 2668.45C1901.05 2668.36 1897.86 2668.25 1894.68 2668.13C1886.99 2667.86 1879.08 2667.54 1871.12 2667.15C1752.07 2661.42 1607.1 2641.79 1549.95 2583.11C1479.52 2510.88 1445.24 2196.79 1472.01 1987.89C1473.42 2168.51 1505.93 2360.25 1559.01 2414.69C1585.25 2441.61 1617.14 2323.94 1611.8 2266.33C1598.03 2117.77 1669.98 1935.96 1799.87 1813.33C1803.24 1810.14 1884.03 1815.25 1950.21 1803.98V1803.99Z"
                        fill="#D8D8D8"
                      />
                      <path
                        id="Vector_68"
                        d="M1960.92 2683.68C1836.24 2683.68 1616.56 2671.88 1539.74 2593.05C1450.35 2501.33 1416.27 2053.24 1484.49 1866.6C1534.65 1729.37 1922.07 1698.2 1938.53 1696.94L1952.17 1695.9L2064.62 2680.7L2049.62 2681.55C2044.96 2681.81 2010.42 2683.68 1960.92 2683.68ZM1927.07 1726.41C1811.19 1737 1546.17 1780.59 1511.17 1876.35C1481.44 1957.68 1470.02 2104.95 1481.38 2260.68C1492.47 2412.86 1523.36 2535.54 1560.09 2573.22C1647.3 2662.71 1960.47 2656.53 2032.98 2653.84L1927.08 1726.4L1927.07 1726.41Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_69"
                        d="M1439.04 2235.26C1462.48 2214.26 1620.8 2207.11 1620.8 2207.11C1638.37 2207.77 1653.26 2220.2 1657.03 2237.37C1670.82 2300.21 1695.1 2446.85 1635.42 2497.8C1585.87 2540.1 1513.15 2525.86 1472.24 2490.44C1440.32 2462.81 1431.05 2408.85 1427.1 2354.68C1422.82 2295.99 1420.76 2251.65 1439.05 2235.26H1439.04Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_70"
                        d="M1427.08 2354.7C1429.09 2382.63 1432.59 2410.5 1439.86 2434.72C1446.63 2457.49 1456.77 2477.05 1472.23 2490.43C1513.14 2525.85 1585.86 2540.1 1635.41 2497.78C1682.22 2457.82 1677.38 2358.9 1666.46 2287.85C1666.43 2287.79 1666.45 2287.75 1666.45 2287.75C1663.46 2268.27 1659.98 2250.86 1657.03 2237.36C1653.27 2220.2 1638.36 2207.75 1620.8 2207.11C1620.8 2207.11 1462.48 2214.23 1439.02 2235.27C1420.77 2251.65 1422.8 2296 1427.08 2354.7V2354.7Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_71"
                        d="M1439.86 2434.72C1446.63 2457.49 1456.77 2477.05 1472.23 2490.43C1513.14 2525.85 1585.86 2540.1 1635.41 2497.78C1682.22 2457.82 1677.38 2358.9 1666.46 2287.85C1666.25 2290.8 1661.63 2365.31 1637.43 2360.63C1618.39 2356.98 1605.64 2319.2 1604.86 2316.97C1604.61 2321.82 1596.61 2468.98 1516.77 2464.53C1480.86 2462.55 1456.44 2450.06 1439.87 2434.73L1439.86 2434.72Z"
                        fill="#D8D8D8"
                      />
                      <path
                        id="Vector_72"
                        d="M1562.51 2537.92C1525.65 2537.92 1488.94 2523.7 1462.93 2501.18C1425.87 2469.1 1416.86 2409.65 1412.92 2355.72C1408.12 2289.88 1406.68 2245.18 1429.56 2224.68C1454.81 2202.05 1581.83 2194.65 1620.16 2192.91L1620.75 2192.89L1621.34 2192.91C1645.37 2193.82 1665.75 2210.85 1670.9 2234.32C1688.28 2313.54 1707.98 2454.52 1644.64 2508.6C1620.62 2529.11 1591.52 2537.92 1562.52 2537.92H1562.51ZM1620.78 2221.33C1547.21 2224.69 1460.62 2235 1448.51 2245.84C1434.31 2258.56 1438.15 2311.29 1441.24 2353.65C1444.55 2398.97 1452.12 2454.25 1481.52 2479.7C1518.91 2512.07 1583.25 2523.64 1626.18 2486.99C1681.72 2439.58 1653.2 2286.26 1643.14 2240.4C1640.8 2229.74 1631.65 2221.96 1620.77 2221.32L1620.78 2221.33Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_73"
                        d="M1522.87 2504.85C1565.9 2508.17 1588.64 2456.08 1595.71 2419.29C1600.24 2397.2 1601.17 2374.26 1601.29 2351.15C1601.01 2328.61 1600.45 2305.05 1594.32 2283.36C1593.49 2281.34 1594.52 2278.97 1596.63 2278.31C1598.51 2277.7 1600.53 2278.68 1601.26 2280.49C1605.73 2292.15 1607.67 2303.48 1609.65 2315.52C1614.43 2350.88 1616.58 2386.11 1611.96 2422.01C1606.81 2469.69 1576.22 2534.32 1518.72 2529.59C1501.74 2526.84 1505.94 2501.88 1522.88 2504.86L1522.87 2504.85Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_74"
                        d="M1739.64 1849.93C1662.29 1850.89 1582.18 1852.18 1507.35 1872.68C1489.37 1877.73 1481.19 1851.48 1499.02 1845.51C1558.93 1831.78 1619.68 1832.17 1680.49 1832.87C1700.42 1833.32 1720.24 1834.05 1740.22 1835.3C1744.26 1835.56 1747.32 1839.03 1747.07 1843.07C1746.83 1846.99 1743.53 1850 1739.65 1849.93H1739.64Z"
                        fill="#371A45"
                      />
                    </g>
                    <path
                      id="Vector_75"
                      d="M2056.48 2554.99L2172.87 2720.1C2172.87 2720.1 2115.01 2741.78 2094.02 2793.9C2064.2 2867.94 2132.98 3047.21 2263.43 3035.71C2335.02 3029.4 2360.44 2968.38 2335.46 2929.53C2445.5 2904.43 2493.66 2852.68 2511.14 2809.83C2565.2 2677.35 2444.53 2488.39 2369.24 2378.34"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_76"
                      d="M2511.15 2809.83C2493.65 2852.69 2445.5 2904.42 2335.47 2929.52C2360.43 2968.36 2335.01 3029.4 2263.43 3035.72C2174.03 3043.6 2113.6 2961.9 2094.03 2885.85C2085.01 2850.91 2084.66 2817.18 2094.03 2793.89C2115.01 2741.77 2172.89 2720.11 2172.89 2720.11L2056.5 2555.01L2369.23 2378.33C2387.44 2404.96 2408.29 2436.17 2428.7 2470.08C2492.65 2576.46 2552.12 2709.39 2511.14 2809.83H2511.15Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_77"
                      d="M2428.7 2470.07C2431.08 2575.91 2319.41 2624.8 2270.88 2663.43C2218.33 2705.22 2228.95 2780.49 2228.95 2780.49C2228.95 2780.49 2178.96 2756.7 2137.17 2793.49C2115.01 2813.02 2101.84 2848.81 2094.03 2885.84C2085.01 2850.9 2084.66 2817.17 2094.03 2793.88C2115.01 2741.76 2172.89 2720.1 2172.89 2720.1L2056.5 2555L2369.23 2378.32C2387.44 2404.95 2408.29 2436.16 2428.7 2470.07V2470.07Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_78"
                      d="M2068.09 2546.81L2184.47 2711.91C2188.99 2718.32 2187.46 2727.18 2181.04 2731.7C2149.67 2745.35 2118.58 2768.31 2106.41 2801.26C2095.88 2833.74 2102.98 2870.48 2114.07 2902.84C2140.19 2978.92 2212.98 3049.09 2296.74 3012.12C2324.38 2999.28 2341.03 2963.32 2323.14 2936.61C2318.31 2928.55 2323.14 2917.64 2332.28 2915.69C2359.53 2909.47 2386.06 2900.73 2410.68 2888.69C2564.7 2816.56 2509.96 2650.82 2447.36 2532.02C2420.9 2481.75 2389.9 2433.71 2357.5 2386.38C2353.07 2379.91 2354.72 2371.07 2361.2 2366.64C2367.67 2362.21 2376.51 2363.86 2380.94 2370.34C2429.96 2442.14 2477.38 2517.03 2509.28 2598.77C2578.09 2775.37 2529.44 2899.36 2338.59 2943.4L2347.38 2921.87C2383.08 2982.12 2331.75 3044.15 2268.74 3049.42C2152.47 3063.01 2073.66 2937.66 2072.91 2833.49C2070.95 2779.25 2111.07 2733.44 2157.98 2711.21C2161.17 2709.67 2163.99 2708.34 2167.86 2706.82L2161.24 2728.3L2044.85 2563.2C2034.52 2547.83 2056.74 2531.67 2068.07 2546.84L2068.09 2546.81Z"
                      fill="#371A45"
                    />
                    <path
                      id="Vector_79"
                      d="M2362.37 1969.23C2417.75 2193.45 2466.93 2413.08 2381.68 2520.26L2251.85 2636.07C2070.86 2707.02 1933.53 2668.54 1850.77 2608.56C1727.86 2519.47 1699.21 2355.78 1695.12 2272.59C1688.37 2135.08 1808.94 1800.43 1808.94 1800.43C1808.94 1800.43 2230.11 1629.77 2251.98 1727.73"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_80"
                      d="M2413.54 2458.18C2406.23 2481.65 2395.79 2502.49 2381.66 2520.28L2251.85 2636.06C2070.84 2707.04 1933.51 2668.55 1850.78 2608.58C1727.86 2519.46 1699.21 2355.78 1695.12 2272.59C1693.06 2230.59 1702.86 2170.23 1717.52 2106.64C1750.75 1961.92 1808.95 1800.44 1808.95 1800.44C1808.95 1800.44 2230.12 1629.8 2251.99 1727.72L2362.38 1969.23C2408.57 2156.27 2450.47 2340.16 2413.54 2458.18V2458.18Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_81"
                      d="M2413.54 2458.18C2406.23 2481.65 2395.79 2502.49 2381.66 2520.28L2251.85 2636.06C2070.84 2707.04 1933.51 2668.55 1850.78 2608.58C1727.86 2519.46 1699.21 2355.78 1695.12 2272.59C1693.06 2230.59 1702.86 2170.23 1717.52 2106.64L1843.46 2084.88C1843.46 2084.88 1776.18 2482.22 2059.94 2550.4C2220.14 2588.89 2337.28 2527.46 2413.54 2458.19V2458.18Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_82"
                      d="M2339.65 1919.52C2268.96 1956.62 2181.76 1978.53 2087.42 1978.53C1966.81 1978.53 1857.91 1942.74 1780.22 1885.19C1796.69 1834.42 1808.94 1800.44 1808.94 1800.44C1808.94 1800.44 2230.11 1629.8 2251.98 1727.72L2339.64 1919.52H2339.65Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_83"
                      d="M2245.05 1729.28C2206.34 1680.23 2045.8 1707.03 1988.18 1717.46C1933.82 1728.43 1878.25 1742.01 1826.9 1761.65C1817.12 1765.7 1807.33 1769.5 1799.84 1775.16C1786.44 1793.39 1781.11 1816.89 1773.8 1839.22C1763.43 1874.85 1755.1 1911.49 1747.55 1948.21C1732.73 2021.75 1720.97 2096.2 1714.03 2170.69C1706.21 2244.97 1709.18 2318.54 1729.12 2390.24C1780.21 2589.3 1940.12 2689.77 2141.78 2648.98C2178 2642.3 2213.52 2631.39 2249.09 2619.45C2252.83 2618.15 2256.93 2620.16 2258.18 2623.92C2259.3 2627.24 2257.82 2630.81 2254.85 2632.44C2187.77 2669.15 2109.8 2690.04 2031.19 2685.81C1808.23 2673.33 1694.4 2490.63 1680.51 2283.6C1675.9 2168.1 1696.57 2054.75 1718 1942.17C1725.75 1904.88 1734.26 1867.74 1745.09 1830.8C1753.07 1806.13 1759.82 1778.86 1776.21 1756.89C1786.76 1745.16 1802.92 1739.77 1816.09 1734.19C1870.84 1713.7 1926.04 1700.71 1982.78 1689.56C2044.51 1678.32 2218.66 1644.48 2255.2 1708.45C2258.95 1715.58 2263.31 1733 2250.87 1732.93C2248.31 1732.75 2246.4 1731.23 2245.04 1729.29L2245.05 1729.28Z"
                      fill="#371A45"
                    />
                    <path
                      id="Vector_84"
                      d="M2375.76 2516.33C2394.77 2481.38 2402.71 2441.79 2405.52 2402.44C2411.08 2350.98 2408.42 2298.94 2401.97 2247.5C2390.86 2154.71 2368.87 2063.73 2355.47 1970.93C2354.92 1967.03 2357.64 1963.42 2361.54 1962.86C2364.95 1962.38 2368.13 1964.41 2369.26 1967.52C2385.19 2012.07 2397.48 2057.68 2407.75 2103.84C2423.17 2173.2 2433.92 2244.18 2436.66 2315.49C2438.35 2363.81 2437.22 2412.8 2421.25 2459.1C2413.67 2482.35 2402.53 2504.78 2387.57 2524.2C2385.17 2527.32 2380.7 2527.89 2377.59 2525.5C2374.73 2523.3 2374.02 2519.36 2375.75 2516.33H2375.76Z"
                      fill="#371A45"
                    />
                    <path
                      id="Vector_85"
                      d="M2034.98 2623.04C2034.98 2623.04 2034.98 2896.7 1972.35 3095.14C1970.45 3187.11 1989.27 3340.5 1830.82 3340.56C1679.1 3340.61 1694.25 3189.57 1697.31 3115.34C1701.7 3008.72 1704.65 2829.2 1706.79 2778.96C1711.72 2663.04 1679.91 2429.51 1700.21 2186.69"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_86"
                      d="M2034.98 2623.03C2034.98 2623.03 2034.98 2654.52 2032.53 2703.91C2027.77 2800.06 2013.75 2964.03 1972.35 3095.15C1970.47 3187.11 1989.25 3340.49 1830.82 3340.57C1679.1 3340.61 1694.23 3189.56 1697.28 3115.32C1701.68 3008.7 1704.63 2829.18 1706.76 2778.94C1707.26 2767.44 1707.36 2754.8 1707.22 2741.06C1705.91 2616.61 1681.9 2405.39 1700.19 2186.67L2034.97 2623.04L2034.98 2623.03Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_87"
                      d="M2034.98 2623.03C2034.98 2623.03 2034.98 2654.52 2032.53 2703.91C1885.5 2629.88 1763.5 2697.8 1707.23 2741.05C1705.92 2616.6 1681.91 2405.38 1700.2 2186.66L2034.98 2623.03Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_88"
                      d="M2042.08 2623.04C2047.42 2703.26 2042.84 2783.29 2034.28 2863.07C2025.08 2943.09 2011.44 3022.69 1985.89 3099.41L1986.54 3095.43C1986 3127.28 1987.31 3160.34 1984.76 3193.83C1980.28 3269.09 1950.49 3338 1868.48 3351.59C1793.61 3364.23 1718.55 3342.05 1693.65 3263.63C1672.71 3198.5 1684.22 3130.62 1684.92 3064.54C1688.03 2969.83 1689.66 2865.95 1692.74 2771.68C1693.6 2739.92 1692.37 2707.54 1690.92 2675.16C1682.37 2513.17 1672.26 2347.95 1686.05 2185.48C1686.7 2177.66 1693.56 2171.85 1701.37 2172.5C1709.19 2173.15 1715 2180.01 1714.35 2187.83C1703.68 2316.67 1707.33 2446.47 1714.24 2576.25C1717.2 2635.14 1722.6 2714.27 1721.13 2772.58C1718.08 2866.38 1716.33 2970.99 1713.31 3065.46C1712.76 3126.81 1701.65 3196.12 1720.56 3254.54C1740.63 3319.25 1802.94 3333.55 1863.46 3323.62C1972.27 3305.54 1955.52 3175.59 1958.64 3091.42C1980.58 3015.59 1994.26 2937.77 2004.42 2859.47C2013.86 2791.08 2021.3 2692.65 2027.88 2623.03C2028.4 2614.69 2041.32 2613.95 2042.08 2623.03V2623.04Z"
                      fill="#371A45"
                    />
                    <g id="Group_17">
                      <path
                        id="Vector_89"
                        d="M2359.67 2261.17C2359.67 2261.17 2407.58 2265.76 2424.16 2249.13C2424.16 2249.13 2487.37 2284.4 2442.66 2341.1C2408.33 2384.63 2316.06 2373.79 2316.06 2373.79"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_90"
                        d="M2316.05 2373.78C2316.05 2373.78 2408.31 2384.63 2442.65 2341.1C2465.4 2312.23 2460.2 2288.91 2449.59 2273.01C2447.07 2279.9 2442.99 2287.18 2436.92 2294.88C2413.56 2324.56 2363.2 2328.94 2333.51 2328.74L2316.04 2373.78H2316.05Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_91"
                        d="M2340.9 2389.2C2326.05 2389.2 2315.68 2388.04 2314.4 2387.89C2306.61 2386.97 2301.04 2379.92 2301.95 2372.13C2302.86 2364.34 2309.98 2358.8 2317.71 2359.68C2341.21 2362.4 2407.46 2362.78 2431.51 2332.3C2441.98 2319.02 2446.13 2306.44 2443.82 2294.91C2441.23 2281.95 2431.2 2271.98 2424.23 2266.44C2401.53 2278.61 2366.15 2276.06 2358.32 2275.31C2350.51 2274.56 2344.79 2267.63 2345.54 2259.82C2346.28 2252.02 2353.17 2246.27 2361.02 2247.03C2377.67 2248.62 2406.08 2247.16 2414.12 2239.11L2421.71 2231.5L2431.09 2236.73C2432.48 2237.5 2465.03 2256.01 2471.69 2289.35C2475.69 2309.41 2469.68 2329.78 2453.82 2349.89C2427.19 2383.65 2373.38 2389.2 2340.91 2389.2H2340.9Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_92"
                        d="M2349.18 2455.31L2067.99 2467.74C2034.71 2469.22 2006.25 2443.19 2004.77 2409.87L1998.16 2260.24C1996.87 2231.07 2016.68 2205.64 2043.99 2198.78C2047.86 2197.78 2051.88 2197.21 2056.03 2197.02L2337.17 2184.59C2370.49 2183.11 2398.95 2209.14 2400.39 2242.46L2405.65 2360.97L2407.04 2392.09C2408.52 2425.41 2382.45 2453.83 2349.17 2455.31H2349.18Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_93"
                        d="M2349.18 2455.31L2067.99 2467.74C2034.71 2469.22 2006.25 2443.19 2004.77 2409.87L1998.16 2260.24C1996.87 2231.07 2016.68 2205.64 2043.99 2198.78C2047.86 2197.78 2051.88 2197.21 2056.03 2197.02L2337.17 2184.59C2370.49 2183.11 2398.95 2209.14 2400.39 2242.46L2405.65 2360.97L2407.04 2392.09C2408.52 2425.41 2382.45 2453.83 2349.17 2455.31H2349.18Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_94"
                        d="M1998.16 2260.23L2001.02 2325.18L2004.76 2409.87C2006.23 2443.19 2034.7 2469.24 2067.98 2467.75L2349.17 2455.32C2382.45 2453.84 2408.52 2425.42 2407.05 2392.1L2405.66 2360.97L2404.42 2333.22L2400.4 2242.45C2398.93 2209.13 2370.5 2183.1 2337.18 2184.57L2056.03 2197.01C2051.87 2197.2 2047.82 2197.77 2043.97 2198.75C2016.67 2205.62 1996.87 2231.07 1998.15 2260.23H1998.16Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_95"
                        d="M2001.03 2325.18L2004.77 2409.87C2006.24 2443.19 2034.71 2469.24 2067.99 2467.75L2349.18 2455.32C2382.46 2453.84 2408.53 2425.42 2407.06 2392.1L2405.67 2360.97L2404.43 2333.22C2395.69 2359.89 2378.1 2393.36 2343.88 2402.77C2261.06 2425.54 2217.78 2388.92 2196.55 2317.01C2196.55 2317.01 2117.29 2295.58 2104.1 2315.76C2090.86 2335.97 2098.44 2372.43 2080.92 2369.25C2063.43 2366.08 2063.84 2307.57 2063.84 2307.57L2001.03 2325.17V2325.18Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_96"
                        d="M2064.38 2482.06C2025.14 2482.06 1992.39 2451.04 1990.63 2411.47L1983.93 2259.91C1982.13 2219.23 2013.76 2184.67 2054.45 2182.86L2337.52 2170.34C2378.42 2168.58 2412.76 2200.18 2414.57 2240.86L2421.27 2392.42C2423.06 2433.1 2391.43 2467.66 2350.75 2469.47L2067.68 2481.99C2066.57 2482.04 2065.47 2482.06 2064.37 2482.06H2064.38ZM2340.81 2198.68C2340.14 2198.68 2339.46 2198.69 2338.78 2198.72L2055.71 2211.24C2030.67 2212.35 2011.2 2233.61 2012.31 2258.65L2019.01 2410.21C2019.54 2422.28 2024.79 2433.45 2033.78 2441.68C2042.77 2449.9 2054.39 2454.15 2066.43 2453.61L2349.5 2441.09C2374.54 2439.98 2394.01 2418.71 2392.9 2393.68L2386.2 2242.12C2385.13 2217.76 2364.96 2198.68 2340.82 2198.68H2340.81Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_97"
                        d="M1998.16 2260.23L1999.01 2279.81L2000.86 2321.45L2001.86 2343.4L2004.81 2409.88C2005.18 2418.74 2007.51 2427.15 2011.33 2434.6C2021.8 2455.16 2043.6 2468.79 2068 2467.76L2139.9 2463.53C2106.63 2464.98 2078.16 2438.93 2076.68 2405.65L2074.32 2352.12L2073.63 2336.81L2070.09 2255.98C2068.79 2226.51 2089.03 2200.88 2116.81 2194.34L2056.06 2197.03C2022.78 2198.51 1996.71 2226.93 1998.18 2260.25L1998.16 2260.23Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_98"
                        d="M1999.02 2279.81L2000.87 2321.45L2001.87 2343.4L2004.82 2409.88C2005.19 2418.74 2007.52 2427.15 2011.34 2434.6C2021.81 2455.16 2043.61 2468.79 2068.01 2467.76L2139.91 2463.53C2106.64 2464.98 2078.17 2438.93 2076.69 2405.65L2074.33 2352.12C2069.55 2355.53 2063.68 2357.42 2059.49 2351.1C2051.18 2338.59 2045.69 2282.96 2025.99 2276.42C2019.44 2274.23 2009.44 2276.2 1999.03 2279.83L1999.02 2279.81Z"
                        fill="#D8D8D8"
                      />
                      <path
                        id="Vector_99"
                        d="M2065.27 2482.01C2046.57 2482.01 2028.81 2475.09 2014.87 2462.34C2000.09 2448.8 1991.47 2430.4 1990.6 2410.5L1983.98 2260.86C1983.09 2240.97 1990.05 2221.87 2003.58 2207.09C2017.11 2192.32 2035.51 2183.69 2055.41 2182.8L2116.15 2180.12L2120.03 2208.13C2098.32 2213.24 2083.28 2233.1 2084.26 2255.33L2090.88 2404.98C2091.42 2417.3 2096.77 2428.71 2105.95 2437.12C2115.12 2445.51 2126.92 2449.88 2139.25 2449.29C2198.96 2446.65 2310.73 2441.7 2320.25 2441.26L2335.15 2440.54L2335.8 2454.83C2336.23 2465.18 2329.29 2468.27 2322.1 2469.34V2469.61L2068.67 2481.92C2067.53 2481.97 2066.39 2482 2065.26 2482L2065.27 2482.01ZM2069.22 2210.63L2056.67 2211.18C2044.36 2211.73 2032.94 2217.09 2024.54 2226.27C2016.14 2235.45 2011.82 2247.29 2012.37 2259.6L2018.99 2409.26C2019.53 2421.57 2024.88 2432.99 2034.06 2441.39C2043.24 2449.79 2055.05 2454.17 2067.36 2453.56L2081.61 2452.87C2070 2439.99 2063.27 2423.69 2062.51 2406.24L2055.89 2256.6C2055.14 2239.73 2060.11 2223.72 2069.22 2210.63V2210.63Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_100"
                        d="M2184.31 2327.11C2184.52 2332.64 2178.46 2377.62 2178.46 2377.62C2178.46 2377.62 2164.31 2378.16 2146.93 2378.83C2125.22 2379.67 2106.75 2357.86 2105.68 2330.13C2104.61 2302.4 2121.35 2279.24 2143.06 2278.4C2157.71 2277.84 2174.59 2277.19 2174.59 2277.19C2174.59 2277.19 2183.96 2318.09 2184.3 2327.11H2184.31Z"
                        fill="#22A7F0"
                      />
                      <path
                        id="Vector_101"
                        d="M2184.31 2327.11C2184.52 2332.64 2178.46 2377.62 2178.46 2377.62C2178.46 2377.62 2164.31 2378.16 2146.93 2378.83C2125.22 2379.67 2106.75 2357.86 2105.68 2330.13C2104.61 2302.4 2121.35 2279.24 2143.06 2278.4C2157.71 2277.84 2174.59 2277.19 2174.59 2277.19C2174.59 2277.19 2183.96 2318.09 2184.3 2327.11H2184.31Z"
                        fill="#1675DB"
                      />
                      <path
                        id="Vector_102"
                        d="M2145.73 2389.51C2118.88 2389.51 2096.3 2363.45 2095.03 2330.54C2093.74 2296.99 2115.11 2268.82 2142.65 2267.76L2170.41 2266.69C2177.87 2266.51 2184.22 2271.41 2185.82 2278.55C2189.1 2293.13 2194.64 2318.79 2194.95 2326.7C2195 2328.15 2195.17 2332.56 2189.52 2375.25C2188.55 2382.58 2182.47 2388.12 2175.06 2388.42L2147.34 2389.48C2146.81 2389.5 2146.27 2389.51 2145.74 2389.51H2145.73ZM2166.14 2288.18L2143.47 2289.05C2127.93 2289.65 2115.49 2308.27 2116.32 2329.72C2117.14 2351.17 2131.06 2368.91 2146.52 2368.19L2169.08 2367.33C2172.24 2343.16 2173.66 2329.95 2173.66 2327.46C2173.48 2323.43 2170.52 2307.97 2166.14 2288.18V2288.18Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_103"
                        d="M2178.34 2377.72C2200.06 2376.88 2216.8 2353.73 2215.73 2326C2214.67 2298.26 2196.2 2276.46 2174.48 2277.29C2152.77 2278.13 2136.03 2301.28 2137.09 2329.02C2138.16 2356.75 2156.63 2378.55 2178.34 2377.72Z"
                        fill="#22A7F0"
                      />
                      <path
                        id="Vector_104"
                        d="M2137.21 2328.93C2138.27 2356.65 2156.74 2378.47 2178.44 2377.63C2200.18 2376.8 2216.91 2353.63 2215.83 2325.88C2215.7 2322.14 2215.22 2318.48 2214.46 2314.98C2209.61 2334.24 2195.75 2348.37 2178.88 2348.99C2160.13 2349.71 2143.77 2333.55 2139.02 2311.22C2137.61 2316.76 2136.99 2322.73 2137.21 2328.93V2328.93Z"
                        fill="#1675DB"
                      />
                      <path
                        id="Vector_105"
                        d="M2137.21 2328.93C2137.28 2330.82 2137.44 2332.67 2137.69 2334.5C2141.17 2312.61 2155.98 2295.95 2174.32 2295.25C2194.55 2294.49 2212 2313.37 2215.07 2338.41C2215.73 2334.36 2215.98 2330.19 2215.83 2325.88C2214.77 2298.16 2196.3 2276.34 2174.6 2277.18C2152.89 2278.04 2136.16 2301.18 2137.21 2328.93V2328.93Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_106"
                        d="M2177.21 2388.29C2163.98 2388.29 2151.51 2382.15 2141.96 2370.86C2132.64 2359.85 2127.17 2345.1 2126.56 2329.32C2125.95 2313.54 2130.27 2298.41 2138.72 2286.72C2147.74 2274.24 2160.34 2267.07 2174.18 2266.54C2188.07 2266.05 2201.13 2272.19 2211.08 2283.94C2220.4 2294.95 2225.87 2309.7 2226.48 2325.48C2227.09 2341.26 2222.77 2356.39 2214.32 2368.08C2205.3 2380.56 2192.7 2387.73 2178.86 2388.26C2178.31 2388.28 2177.76 2388.29 2177.21 2388.29V2388.29ZM2175.78 2287.82C2175.52 2287.82 2175.26 2287.82 2175 2287.83C2167.84 2288.1 2161.09 2292.14 2155.99 2299.2C2150.32 2307.05 2147.43 2317.46 2147.86 2328.5C2148.29 2339.54 2151.96 2349.7 2158.22 2357.09C2163.85 2363.73 2170.9 2367.22 2178.05 2366.97C2185.21 2366.7 2191.96 2362.66 2197.06 2355.6C2202.73 2347.75 2205.62 2337.35 2205.19 2326.3C2204.77 2315.25 2201.09 2305.1 2194.83 2297.71C2189.41 2291.31 2182.67 2287.82 2175.78 2287.82V2287.82Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_107"
                        d="M2261.72 2468.74C2255.94 2468.74 2251.19 2464.12 2251.08 2458.31L2249.34 2373.54C2249.02 2357.98 2256.47 2343.4 2269.27 2334.55L2284.24 2324.18C2291.31 2319.29 2295.32 2311.24 2294.97 2302.64L2290.24 2186.7C2290 2180.82 2294.57 2175.86 2300.45 2175.62C2306.17 2175.44 2311.28 2179.95 2311.53 2185.83L2316.26 2301.78C2316.9 2317.71 2309.47 2332.63 2296.37 2341.7L2281.39 2352.07C2274.49 2356.85 2270.47 2364.71 2270.64 2373.1L2272.38 2457.87C2272.5 2463.75 2267.83 2468.62 2261.95 2468.74H2261.73H2261.72Z"
                        fill="#371A45"
                      />
                      <path
                        id="Vector_108"
                        d="M1676.31 2338.81C1770.69 2423.92 2003.37 2397.28 2003.37 2397.28C2003.37 2397.28 2048.79 2382.16 2044.81 2337.28C2041.09 2294.98 1998.54 2293.65 1998.54 2293.65C1962.79 2296.87 1928.87 2297.23 1897.5 2295.74C1773.54 2289.87 1689.29 2255.35 1689.29 2255.35C1689.29 2255.35 1665.95 2274.33 1661.19 2298.14C1658.53 2311.01 1661.39 2325.35 1676.32 2338.81H1676.31Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_109"
                        d="M2045.06 2343.25C2044.74 2383.51 2003.38 2397.29 2003.38 2397.29C2003.38 2397.29 1770.68 2423.92 1676.3 2338.81C1661.39 2325.35 1658.51 2311.01 1661.17 2298.16C1665.93 2274.34 1689.29 2255.34 1689.29 2255.34C1689.29 2255.34 1740.35 2276.29 1821.44 2288.32C1844.52 2291.73 1870.05 2294.43 1897.49 2295.74C1928.84 2297.23 1962.79 2296.88 1998.54 2293.65C1998.54 2293.65 2041.08 2295 2044.8 2337.29C2044.98 2339.35 2045.05 2341.34 2045.05 2343.26L2045.06 2343.25Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_110"
                        d="M2045.06 2343.25C2044.74 2383.51 2003.38 2397.29 2003.38 2397.29C2003.38 2397.29 1770.68 2423.92 1676.3 2338.81C1661.39 2325.35 1658.51 2311.01 1661.17 2298.16C1665.93 2274.34 1689.29 2255.34 1689.29 2255.34C1689.29 2255.34 1740.35 2276.29 1821.44 2288.32C1820.73 2315.59 1833.16 2351.52 1899.09 2361.92C1963.07 2372.04 2015.02 2356.42 2045.05 2343.24L2045.06 2343.25Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_111"
                        d="M1920.69 2415.56C1842.15 2415.56 1728.37 2404.86 1666.82 2349.34C1649.17 2333.42 1645.81 2316.48 1646.11 2305.08C1646.95 2272.38 1676.91 2247.11 1680.32 2244.33C1684.35 2241.05 1689.84 2240.23 1694.66 2242.21C1695.95 2242.74 1826.27 2294.94 1997.27 2279.5C1997.84 2279.45 1998.4 2279.43 1998.99 2279.45C2018.07 2280.05 2055.16 2292.65 2058.97 2336.03C2062.88 2380.38 2027.25 2404.3 2007.87 2410.76C2006.94 2411.07 2005.98 2411.28 2005 2411.39C2001.37 2411.81 1967.21 2415.56 1920.7 2415.56H1920.69ZM1692.5 2271.77C1684.92 2279.65 1674.8 2292.99 1674.51 2305.88C1674.32 2313.88 1678.04 2321.19 1685.85 2328.24C1770.32 2404.45 1979.32 2385.45 2000.11 2383.34C2006.32 2380.85 2033.29 2368.26 2030.68 2338.52C2028.29 2311.31 2003.47 2308.22 1998.81 2307.88C1844.49 2321.58 1724.99 2283.53 1692.5 2271.77Z"
                        fill="#371A45"
                      />
                    </g>
                    <path
                      id="Vector_112"
                      d="M1972.93 1753.05C1972.93 1753.05 1783.43 1706.38 1627.74 1942.54C1597.57 1988.3 1537.95 2105.39 1517.52 2245.4C1493.13 2412.56 1500.73 2596.49 1569.75 2677.25C1604.66 2718.11 1644.4 2713.2 1674.54 2722.44C1794.03 2786.78 1793.33 2657.15 1796.63 2635.94C1817.84 2634.53 1847.84 2628.76 1848.48 2591.63C1849.19 2550.62 1796.99 2501.47 1796.99 2501.47C1796.99 2501.47 1754.2 2315.26 1846.63 2100.28"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_113"
                      d="M1848.04 2583.94C1848.39 2586.53 1848.54 2589.09 1848.5 2591.64C1847.86 2628.74 1817.86 2634.53 1796.63 2635.95C1793.33 2657.15 1794.04 2786.78 1674.56 2722.44C1644.42 2713.17 1604.68 2718.11 1569.75 2677.24C1552.49 2657.04 1539.07 2630.41 1529.06 2599.2C1529.02 2599.02 1528.95 2598.88 1528.92 2598.77C1528.85 2598.59 1528.78 2598.41 1528.74 2598.2C1528.7 2598.13 1528.67 2598.06 1528.67 2597.99C1498.92 2504.4 1499.31 2370.22 1517.52 2245.38C1537.97 2105.38 1597.59 1988.28 1627.73 1942.52C1783.42 1706.37 1972.95 1753.03 1972.95 1753.03L1846.62 2100.28C1754.2 2315.27 1796.98 2501.46 1796.98 2501.46C1796.98 2501.46 1842.89 2544.71 1848.04 2583.94V2583.94Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_114"
                      d="M1848.5 2591.64C1847.86 2628.74 1817.86 2634.53 1796.63 2635.95C1793.33 2657.15 1794.04 2786.78 1674.56 2722.44C1644.42 2713.17 1604.68 2718.11 1569.75 2677.24C1552.49 2657.04 1539.07 2630.41 1529.06 2599.2C1532.22 2608.29 1555.51 2666.98 1632.67 2674.37C1732.73 2683.99 1748.38 2702.7 1763.19 2672.13C1778 2641.56 1753.11 2600.62 1787.65 2580.45C1801.5 2600.51 1825.36 2607.93 1848.05 2583.93C1848.4 2586.52 1848.55 2589.08 1848.51 2591.63L1848.5 2591.64Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_115"
                      d="M1972.95 1753.03L1901.94 1948.28C1827.95 1922.68 1765.85 1882.27 1723.13 1832.42C1851.45 1723.13 1972.95 1753.03 1972.95 1753.03Z"
                      fill="#F2F2F2"
                    />
                    <path
                      id="Vector_116"
                      d="M1969.54 1766.84C1865.23 1749.42 1762.84 1804.35 1693.94 1880.43C1658.07 1919.27 1628.36 1963.88 1605.45 2011.61C1558.24 2107.73 1531.18 2212.86 1523.25 2319.54C1515.82 2422.03 1513.99 2537.83 1557.65 2632.41C1574.68 2667.37 1599.37 2694 1637.63 2700.38C1650.47 2703.52 1666.3 2704.3 1681.29 2709.93C1759.2 2752.01 1777.09 2705.65 1782.61 2633.75C1783.54 2627.02 1789.15 2622.07 1795.7 2621.76C1809.57 2620.95 1822.15 2617.66 1827.93 2610.84C1833.73 2604.66 1835.07 2593.45 1833.77 2584.84C1831.34 2570.69 1824.09 2557.51 1815.04 2544.42C1806.94 2532.91 1797.18 2521.46 1787.3 2511.82C1785.16 2509.83 1783.79 2507.33 1783.18 2504.69C1781.05 2495.44 1779.41 2487.02 1778.18 2478.22C1764.57 2383.52 1773.41 2285.15 1801.36 2193.8C1811.19 2160.51 1824.88 2128.58 1840.12 2097.46C1844.03 2089.62 1856.21 2094.54 1853.17 2103.07C1842.79 2135.25 1832.18 2167.22 1824.99 2200.23C1809.22 2265.4 1801.86 2332.46 1802.54 2399.39C1803.04 2432.41 1805.31 2465.78 1810.85 2498.28L1806.75 2491.12C1831.58 2516.45 1855.17 2544.81 1861.87 2580.66C1868.03 2624.01 1838.79 2648.88 1797.59 2650.1L1810.68 2638.11C1808.97 2654.26 1807.56 2671.94 1803.2 2688.11C1783.58 2766.6 1720.7 2764.67 1670.39 2736C1659.3 2732.66 1645.83 2731.17 1631.7 2728.15C1602.75 2723.12 1573.12 2705.87 1555.39 2682.01C1484.6 2588.77 1486.43 2429.25 1494.94 2317.09C1505.24 2182.99 1544.04 2049.82 1615.49 1935.33C1688.08 1821.68 1812.48 1724.46 1954 1736.01C1961.75 1736.88 1968.32 1737.35 1976.33 1739.25C1994.83 1744.24 1988.11 1771.06 1969.54 1766.83V1766.84Z"
                      fill="#371A45"
                    />
                    <g id="Group_18">
                      <g id="Group_19">
                        <path
                          id="Vector_117"
                          d="M2761.55 1336.44C2756.57 1404.91 2741.2 1470.49 2709.81 1495C2687.25 1512.61 2522 1525.19 2522 1525.19L2618.02 1088.94C2618.02 1088.94 2629.14 1091.21 2644.88 1095.31C2677.58 1103.87 2730.25 1120.54 2743.66 1141.78C2758.23 1164.85 2767.61 1252.83 2761.56 1336.45L2761.55 1336.44Z"
                          fill="#F2F2F2"
                        />
                        <path
                          id="Vector_118"
                          d="M2761.55 1336.44C2756.57 1404.91 2741.2 1470.49 2709.81 1495C2687.25 1512.61 2522 1525.19 2522 1525.19L2618.02 1088.94C2618.02 1088.94 2629.14 1091.21 2644.88 1095.31C2663.08 1146.22 2684.08 1223.32 2688.08 1315.64C2690.85 1379.36 2734.8 1380.47 2761.56 1336.45L2761.55 1336.44Z"
                          fill="#D8D8D8"
                        />
                        <path
                          id="Vector_119"
                          d="M2504.02 1540.81L2607.16 1072.26L2620.84 1075.02C2639.49 1078.78 2733.66 1099.35 2755.66 1134.19C2773.11 1161.81 2779.67 1247.57 2776.99 1314.25C2774.72 1370.64 2764.12 1470.56 2718.53 1506.16C2694.47 1524.95 2562.84 1536.32 2523.07 1539.35L2504.01 1540.81H2504.02ZM2628.85 1105.85L2540 1509.47C2612.29 1503.19 2689.81 1492.29 2701.06 1483.77C2763.14 1435.28 2755.08 1186.46 2731.65 1149.35C2721.39 1133.1 2669.37 1115.39 2628.85 1105.84V1105.85Z"
                          fill="#371944"
                        />
                      </g>
                      <g id="Group_20">
                        <path
                          id="Vector_120"
                          d="M2117.04 1878.02C2445.19 1878.02 2711.2 1612.01 2711.2 1283.86C2711.2 955.715 2445.19 689.7 2117.04 689.7C1788.89 689.7 1522.88 955.715 1522.88 1283.86C1522.88 1612.01 1788.89 1878.02 2117.04 1878.02Z"
                          fill="#F2F2F2"
                        />
                        <path
                          id="Vector_121"
                          d="M1552.46 1469.34C1632.1 1712.24 1863.67 1884.76 2132.32 1877.84C2460.35 1869.39 2719.44 1596.59 2710.99 1268.56C2707.69 1140.46 2664.1 1022.86 2592.65 927.67C2549.6 1224.84 2179.87 1464.26 1724.02 1476C1665.45 1477.51 1608.04 1475.18 1552.45 1469.34H1552.46Z"
                          fill="#D8D8D8"
                        />
                        <path
                          id="Vector_122"
                          d="M2116.82 1892.23C1788.56 1892.23 1517.38 1629.57 1508.87 1299.53C1500.23 964.19 1766.02 684.33 2101.37 675.69C2263.78 671.44 2418.17 730.83 2536 842.74C2653.83 954.65 2721.02 1105.74 2725.21 1268.19C2733.85 1603.53 2468.06 1883.38 2132.71 1892.02C2127.41 1892.16 2122.09 1892.23 2116.82 1892.23ZM1537.27 1298.8C1545.51 1618.48 1812.4 1871.9 2131.98 1863.62C2451.67 1855.38 2705.05 1588.6 2696.81 1268.92C2692.82 1114.06 2628.76 970.02 2516.44 863.33C2404.11 756.65 2256.85 700.01 2102.1 704.09C1782.41 712.33 1529.03 979.11 1537.27 1298.8V1298.8Z"
                          fill="#371A45"
                        />
                        <path
                          id="Vector_123"
                          d="M2116.63 1892.26C1960.1 1892.26 1812.03 1833.21 1698.08 1724.99C1580.24 1613.07 1513.05 1461.98 1508.86 1299.54C1500.22 964.19 1766.02 684.33 2101.36 675.69C2251.97 671.87 2397.85 723.54 2512.23 821.33C2643.15 933.21 2720.77 1096.09 2725.21 1268.19C2725.29 1271.41 2725.33 1274.69 2725.36 1277.92C2725.8 1322.6 2721.39 1367.09 2712.24 1410.24C2671.58 1602.58 2539.35 1764.15 2358.53 1842.41C2287.11 1873.32 2211.13 1890.01 2132.69 1892.04C2127.33 1892.18 2121.96 1892.25 2116.62 1892.25L2116.63 1892.26ZM1537.25 1298.81C1541.24 1453.66 1605.3 1597.7 1717.64 1704.4C1829.97 1811.09 1977.06 1867.75 2131.97 1863.66C2206.76 1861.73 2279.19 1845.82 2347.26 1816.37C2519.64 1741.75 2645.7 1587.74 2684.46 1404.38C2693.18 1363.24 2697.38 1320.84 2696.96 1278.34C2696.93 1275.17 2696.89 1272.04 2696.81 1268.96C2692.58 1104.89 2618.58 949.61 2493.77 842.95C2384.75 749.72 2245.66 700.36 2102.09 704.11C1782.4 712.35 1529.01 979.13 1537.25 1298.83V1298.81Z"
                          fill="#371A45"
                        />
                        <path
                          id="Vector_124"
                          d="M2168.99 1870.39C2012.14 1870.39 1863.86 1815.21 1749.78 1714.01C1631.24 1608.86 1563.78 1466.65 1559.83 1313.57C1551.7 997.99 1819.48 734.18 2156.77 725.48C2283.22 722.26 2405.62 754.92 2510.5 820.06C2511.11 820.44 2511.69 820.86 2512.23 821.33C2643.15 933.21 2720.77 1096.09 2725.21 1268.19C2725.29 1271.3 2725.33 1274.46 2725.36 1277.58C2725.5 1279.57 2725.56 1281.53 2725.61 1283.52C2726.71 1326.37 2722.19 1369.14 2712.16 1410.63C2671.58 1602.58 2539.36 1764.15 2358.54 1842.41C2358.04 1842.63 2357.51 1842.82 2356.99 1842.98C2301.95 1859.51 2244.51 1868.65 2186.27 1870.16C2180.5 1870.31 2174.73 1870.38 2168.99 1870.38V1870.39ZM2173.98 753.67C2168.5 753.67 2163.01 753.74 2157.51 753.88C1835.88 762.17 1580.5 1012.92 1588.23 1312.84C1591.97 1457.96 1656.03 1592.89 1768.63 1692.76C1881.5 1792.88 2029.59 1845.9 2185.54 1841.77C2240.98 1840.34 2295.64 1831.68 2348.02 1816.02C2520.02 1741.28 2645.76 1587.45 2684.47 1404.36C2694 1364.9 2698.26 1324.62 2697.22 1284.25C2697.18 1282.57 2697.14 1280.93 2697.02 1279.25C2697 1278.94 2696.98 1278.63 2696.98 1278.32C2696.95 1275.14 2696.91 1272.01 2696.83 1268.93C2692.61 1105.22 2618.92 950.27 2494.61 843.63C2399.22 784.63 2288.72 753.66 2173.99 753.66L2173.98 753.67Z"
                          fill="#371A45"
                        />
                        <path
                          id="Vector_125"
                          d="M1631.63 1311.72C1638.81 1590.24 1886.35 1809.85 2184.51 1802.17C2437.25 1795.66 2645.27 1627.91 2698.35 1407.31C2707.93 1367.66 2712.49 1326.32 2711.4 1283.9C2711.35 1281.98 2711.3 1280.1 2711.16 1278.18C2700.82 1002.37 2454.62 785.87 2158.52 793.5C1860.36 801.18 1624.45 1033.2 1631.63 1311.72V1311.72Z"
                          fill="#371A45"
                        />
                        <path
                          id="Vector_126"
                          d="M1631.63 1311.72C1638.81 1590.24 1886.35 1809.85 2184.51 1802.17C2408.75 1796.39 2597.78 1663.71 2674.61 1479.74C2570.56 1595.74 2415.89 1671.21 2241.53 1675.7C1932.73 1683.66 1674.08 1466.21 1645.96 1183.16C1635.48 1224.38 1630.5 1267.47 1631.64 1311.72H1631.63Z"
                          fill="#220F2D"
                        />
                        <path
                          id="Vector_127"
                          d="M2169.42 1809.86C1874.42 1809.86 1631.29 1589.38 1624.13 1311.92C1620.6 1175.11 1674.3 1045.05 1775.32 945.7C1876.19 846.48 2012.22 789.77 2158.33 786.01C2303.31 782.09 2441.37 831.24 2546.95 923.92C2652.59 1016.65 2713.58 1142.37 2718.66 1277.91C2718.8 1279.67 2718.86 1281.67 2718.9 1283.72C2719.99 1325.99 2715.53 1368.17 2705.64 1409.09C2650.44 1638.47 2436.22 1803.2 2184.7 1809.68C2179.6 1809.81 2174.49 1809.88 2169.41 1809.88L2169.42 1809.86ZM1639.13 1311.52C1646.19 1585.49 1890.67 1802.23 2184.32 1794.66C2429.07 1788.35 2637.45 1628.34 2691.07 1405.54C2700.65 1365.89 2704.97 1325.03 2703.92 1284.08C2703.87 1282.28 2703.83 1280.54 2703.69 1278.74C2693.52 1007.69 2448.84 793.48 2158.72 800.98C2016.42 804.65 1883.99 859.83 1785.84 956.38C1687.82 1052.79 1635.72 1178.91 1639.14 1311.52H1639.13Z"
                          fill="#371A45"
                        />
                        <g id="Group_21" opacity="0.5">
                          <path
                            id="Vector_128"
                            d="M1924.13 985.81C1924.13 985.81 2172.22 890.32 2363.81 988.94C2364.01 989.04 2364.21 989.15 2364.41 989.25C2404.72 1010.04 2453.74 1066.58 2477.78 1104.66C2509.56 1155.01 2523.97 1194.33 2535.29 1229.43C2569.68 1336.2 2565.52 1392.66 2565.52 1392.66C2609.02 1316.71 2623.14 1241.65 2595.38 1152.44C2592.62 1143.65 2589.45 1134.69 2585.89 1125.6C2585.08 1123.5 2584.23 1121.44 2583.34 1119.38C2583.38 1119.34 2583.34 1119.3 2583.27 1119.26C2574.76 1101.34 2562.67 1078.22 2546.81 1053.27C2499.05 977.99 2417.28 885.98 2296.8 869.42C2071.52 838.47 1911.6 977.49 1924.12 985.81H1924.13Z"
                            fill="#F2F2F2"
                          />
                        </g>
                        <g id="Group_22" opacity="0.5">
                          <path
                            id="Vector_129"
                            d="M1827.68 1420.57C1872.6 1418.87 1907.43 1375.66 1905.47 1324.07C1903.52 1272.47 1865.52 1232.02 1820.6 1233.72C1775.68 1235.43 1740.85 1278.63 1742.81 1330.23C1744.76 1381.82 1782.76 1422.27 1827.68 1420.57Z"
                            fill="#F2F2F2"
                          />
                        </g>
                        <g id="Group_23" opacity="0.5">
                          <path
                            id="Vector_130"
                            d="M1865.64 1498.61C1866.81 1477.31 1852.75 1459.22 1834.22 1458.2C1815.69 1457.17 1799.72 1473.61 1798.54 1494.91C1797.36 1516.2 1811.43 1534.3 1829.96 1535.32C1848.49 1536.34 1864.46 1519.91 1865.64 1498.61Z"
                            fill="#F2F2F2"
                          />
                        </g>
                        <g id="Group_24">
                          <g id="Group_25">
                            <path
                              id="Vector_131"
                              d="M1496.38 1218.3L1529.78 1450L1550.59 1594.32L1631.84 1596.09L1639.52 1596.25C1674.76 1590.44 1690.13 1494.1 1673.85 1381.26C1673.5 1378.72 1673.09 1376.12 1672.68 1373.58C1655.69 1264.54 1615.01 1181.9 1580.59 1187.64L1573.32 1190.27L1496.38 1218.31V1218.3Z"
                              fill="#F2F2F2"
                            />
                            <path
                              id="Vector_132"
                              d="M1529.78 1450L1550.59 1594.32L1631.84 1596.09L1639.52 1596.25C1674.76 1590.44 1690.13 1494.1 1673.85 1381.26C1673.5 1378.72 1673.09 1376.12 1672.68 1373.58L1666.96 1360.33C1666.96 1360.33 1659.86 1409.51 1637.24 1417.74C1613.33 1426.49 1593.53 1368.85 1593.53 1368.85L1529.78 1450.01V1450Z"
                              fill="#D8D8D8"
                            />
                          </g>
                          <path
                            id="Vector_133"
                            d="M1639.51 1610.47C1639.4 1610.47 1639.3 1610.47 1639.19 1610.47L1550.27 1608.55C1543.33 1608.4 1537.51 1603.25 1536.52 1596.38L1482.31 1220.34C1481.35 1213.68 1485.19 1207.27 1491.5 1204.97L1575.69 1174.27C1576.52 1173.97 1577.37 1173.74 1578.24 1173.6C1585.42 1172.41 1596.62 1172.95 1609.39 1182.28C1643.4 1207.14 1674.21 1284.47 1687.88 1379.26C1703.5 1487.65 1692.71 1601.81 1641.83 1610.29C1641.06 1610.42 1640.28 1610.49 1639.5 1610.49L1639.51 1610.47ZM1562.92 1580.4L1638.15 1582.03C1657.73 1576.27 1676.02 1496.01 1659.77 1383.3C1646.12 1288.62 1615.66 1222.03 1592.64 1205.19C1590.25 1203.44 1586.83 1201.51 1583.86 1201.52L1512.07 1227.7L1562.92 1580.4V1580.4Z"
                            fill="#371A45"
                          />
                          <path
                            id="Vector_134"
                            d="M1461.46 1388.99C1461.46 1388.99 1461.52 1389.05 1461.52 1389.11C1462.34 1397.98 1463.44 1406.97 1464.76 1416.14C1479.72 1519.94 1518.13 1599.76 1550.58 1594.32C1582.06 1589.08 1596.38 1505.32 1583.4 1405.22C1583.04 1402.37 1582.62 1399.46 1582.2 1396.54C1567.24 1292.74 1528.78 1212.92 1496.37 1218.3C1466.8 1223.25 1452.45 1297.25 1461.45 1388.99H1461.46Z"
                            fill="#F2F2F2"
                          />
                          <path
                            id="Vector_135"
                            d="M1461.52 1389.11C1462.34 1397.98 1463.44 1406.97 1464.76 1416.14C1479.72 1519.94 1518.13 1599.76 1550.58 1594.32C1582.06 1589.08 1596.38 1505.32 1583.4 1405.22C1572.61 1443.1 1548.89 1510.33 1519.28 1494.22C1501.99 1484.75 1478.64 1432.77 1461.51 1389.12L1461.52 1389.11Z"
                            fill="#D8D8D8"
                          />
                          <path
                            id="Vector_136"
                            d="M1547.51 1608.8C1541.02 1608.8 1532.61 1606.92 1523.37 1600.17C1491.84 1577.11 1463.31 1505.67 1450.69 1418.15C1436.24 1317.89 1446.48 1212.24 1494.03 1204.33C1500.83 1203.17 1511.48 1203.69 1523.56 1212.51C1555.1 1235.56 1583.62 1307.01 1596.25 1394.53C1610.7 1494.79 1600.46 1600.43 1552.91 1608.36C1551.31 1608.63 1549.5 1608.8 1547.51 1608.8V1608.8ZM1499.49 1232.28C1499.22 1232.28 1498.96 1232.3 1498.7 1232.34C1483.42 1234.88 1462.55 1301.34 1478.81 1414.09C1491.32 1500.91 1519.14 1561.87 1540.14 1577.23C1542.4 1578.89 1545.62 1580.77 1548.24 1580.33C1563.52 1577.78 1584.39 1511.32 1568.13 1398.57C1555.61 1311.75 1527.8 1250.79 1506.8 1235.44C1504.76 1233.95 1501.93 1232.28 1499.49 1232.28Z"
                            fill="#371A45"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Group_26">
                      <path
                        id="Vector_137"
                        d="M1798.99 2576.08C1798.99 2576.08 1771.77 2595.88 1788.15 2655.98Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_138"
                        d="M1802.13 2580.38C1793.58 2596.81 1793.35 2615.65 1793.46 2634.01C1793.62 2640.98 1793.91 2647.87 1793.24 2655C1792.83 2660.11 1785.58 2661.71 1783.01 2657.37C1768.99 2634.36 1767.54 2601.35 1785.58 2579.86C1789.1 2576.2 1798.04 2566.59 1802.8 2574.05C1803.99 2576.16 1803.01 2578.43 1802.13 2580.38V2580.38Z"
                        fill="#371A45"
                      />
                    </g>
                    <g id="Group_27">
                      <path
                        id="Vector_139"
                        d="M2172.87 2720.1C2172.87 2720.1 2221.32 2786.09 2285.03 2821Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_140"
                        d="M2184.32 2711.69C2212.29 2751.49 2247.02 2788.62 2288.44 2814.76C2291.9 2816.8 2293.01 2821.32 2290.84 2824.71C2288.86 2827.82 2284.84 2828.85 2281.62 2827.21C2270.02 2821.28 2258.64 2814.8 2247.92 2807.42C2226.39 2792.79 2206.58 2775.86 2188.12 2757.54C2178.86 2748.27 2170.16 2739.03 2161.43 2728.49C2156.42 2722.44 2157.26 2713.47 2163.31 2708.46C2169.78 2703.03 2179.77 2704.58 2184.32 2711.68V2711.69Z"
                        fill="#371A45"
                      />
                    </g>
                    <g id="Group_28">
                      <path
                        id="Vector_141"
                        d="M2234.78 2930.73C2282.1 2937.46 2335.46 2929.53 2335.46 2929.53Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_142"
                        d="M2235.78 2923.7C2268.28 2925.61 2301.7 2921.96 2333.49 2915.45C2353.31 2911.97 2357.58 2941.3 2337.54 2943.57C2302.65 2945.7 2268.08 2944.63 2233.78 2937.75C2229.93 2936.96 2227.46 2933.19 2228.25 2929.34C2228.98 2925.79 2232.26 2923.4 2235.78 2923.69V2923.7Z"
                        fill="#371A45"
                      />
                    </g>
                    <path
                      id="Vector_143"
                      d="M2621.06 2329.15C2619.48 2329.15 2617.87 2328.79 2616.35 2328.05C2577.84 2309.03 2552.07 2280.04 2539.77 2241.91C2510.12 2150.03 2568.87 2037.61 2571.38 2032.88C2574.14 2027.68 2580.58 2025.71 2585.77 2028.46C2590.97 2031.21 2592.95 2037.66 2590.2 2042.85C2589.62 2043.93 2533.15 2152.18 2560.06 2235.43C2570.54 2267.85 2592.65 2292.58 2625.78 2308.95C2631.05 2311.56 2633.22 2317.94 2630.61 2323.22C2628.75 2326.98 2624.98 2329.16 2621.05 2329.16L2621.06 2329.15Z"
                      fill="#371A45"
                    />
                    <path
                      id="Vector_144"
                      d="M1794.84 2294.53C1793.31 2294.53 1791.74 2294.2 1790.26 2293.49C1624.06 2214.22 1522.66 2254.79 1521.65 2255.22C1516.23 2257.47 1509.98 2254.93 1507.72 2249.53C1505.44 2244.12 1507.95 2237.89 1513.35 2235.6C1517.78 2233.72 1623.81 2190.51 1799.42 2274.26C1804.73 2276.79 1806.98 2283.15 1804.45 2288.46C1802.62 2292.29 1798.81 2294.53 1794.83 2294.53H1794.84Z"
                      fill="#371A45"
                    />
                    <path
                      id="Vector_145"
                      d="M2307.97 2829.07C2341.24 2842.8 2379.4 2849.29 2415.18 2844.49C2450.87 2840.05 2483.36 2821.87 2506.3 2794.44C2515.58 2783.82 2531.55 2797.21 2522.56 2808.22C2466.61 2871.07 2375.34 2873.16 2303.29 2838.64C2297.05 2835.48 2301.54 2826.07 2307.97 2829.07Z"
                      fill="#371A45"
                    />
                    <path
                      id="Vector_146"
                      d="M1878.15 2921.86C1777.4 2921.86 1699.75 2873.14 1698.63 2872.42C1693.68 2869.25 1692.24 2862.67 1695.41 2857.72C1698.58 2852.76 1705.16 2851.31 1710.12 2854.49C1711.63 2855.45 1863.43 2950.31 2012.45 2865.12C2017.57 2862.21 2024.06 2863.98 2026.99 2869.08C2029.91 2874.19 2028.14 2880.7 2023.03 2883.62C1973.3 2912.04 1923.4 2921.87 1878.15 2921.87V2921.86Z"
                      fill="#371A45"
                    />
                  </g>
                  <path
                    id="js-logo"
                    d="M3182.68 1478.11C3190.2 1493.34 3201.85 1506.14 3216.32 1515.05C3230.78 1523.95 3247.46 1528.59 3264.45 1528.44C3298.79 1528.44 3320.73 1511.26 3320.73 1487.55C3320.73 1459.15 3298.18 1449.07 3260.4 1432.53L3239.67 1423.64C3179.87 1398.16 3140.13 1366.24 3140.13 1298.77C3140.13 1236.6 3187.5 1189.3 3261.51 1189.3C3285.54 1187.56 3309.54 1192.93 3330.52 1204.76C3351.5 1216.58 3368.53 1234.33 3379.48 1255.78L3314.94 1297.22C3310.72 1286.59 3303.35 1277.5 3293.84 1271.15C3284.32 1264.81 3273.1 1261.51 3261.66 1261.69C3256.67 1261.19 3251.64 1261.73 3246.88 1263.28C3242.11 1264.84 3237.72 1267.37 3233.99 1270.71C3230.26 1274.05 3227.26 1278.13 3225.2 1282.7C3223.13 1287.26 3222.04 1292.21 3221.99 1297.22C3221.99 1322.09 3237.39 1332.14 3272.85 1347.55L3293.58 1356.41C3364.01 1386.61 3403.75 1417.39 3403.75 1486.61C3403.75 1561.23 3345.14 1602.11 3266.41 1602.11C3235.74 1604.06 3205.16 1597.1 3178.36 1582.05C3151.56 1567.01 3129.69 1544.54 3115.38 1517.34L3182.68 1478.11ZM2889.93 1485.38C2902.96 1508.48 2914.8 1528 2943.21 1528C2970.43 1528 2987.62 1517.34 2987.62 1475.93V1194.14H3070.5V1477.09C3070.5 1562.9 3020.17 1601.96 2946.74 1601.96C2920.81 1603.37 2895.05 1596.89 2872.86 1583.37C2850.68 1569.86 2833.11 1549.95 2822.45 1526.26L2889.93 1485.38Z"
                    fill="#F5DE19"
                  />
                  <g id="Group 3" filter="url(#filter1_d_225_334)">
                    <path
                      id="Vector_147"
                      d="M959.5 2187.99C989.595 2187.99 1013.99 2163.6 1013.99 2133.5C1013.99 2103.4 989.595 2079.01 959.5 2079.01C929.405 2079.01 905.008 2103.4 905.008 2133.5C905.008 2163.6 929.405 2187.99 959.5 2187.99Z"
                      fill="#61DAFB"
                    />
                    <path
                      id="react-logo"
                      d="M1052.55 2187.13C1088.19 2122.96 1111.95 2056.85 1118.6 2003.33C1125.26 1949.81 1114.27 1913.27 1088.04 1901.74C1061.82 1890.22 1022.51 1904.65 978.758 1941.87C935.01 1979.09 890.407 2036.05 854.76 2100.21C819.113 2164.38 795.34 2230.5 788.671 2284.03C782.003 2337.56 792.984 2374.11 819.199 2385.66C845.415 2397.2 884.718 2382.78 928.463 2345.58C972.209 2308.37 1016.82 2251.43 1052.47 2187.27L1052.55 2187.13ZM866.53 2187.27C902.185 2251.43 946.791 2308.37 990.537 2345.58C1034.28 2382.78 1073.59 2397.2 1099.8 2385.66C1126.02 2374.11 1137 2337.56 1130.33 2284.03C1123.66 2230.5 1099.89 2164.38 1064.24 2100.21C1028.59 2036.05 983.99 1979.09 940.242 1941.87C896.495 1904.65 857.185 1890.22 830.959 1901.74C804.734 1913.27 793.74 1949.81 800.397 2003.33C807.053 2056.85 830.815 2122.96 866.455 2187.13L866.53 2187.27ZM959.576 2240.89C1033.03 2240.89 1103.48 2229.56 1155.41 2209.4C1207.34 2189.24 1236.51 2161.91 1236.5 2133.41C1236.49 2104.91 1207.3 2077.58 1155.36 2057.43C1103.41 2037.27 1032.96 2025.95 959.5 2025.95C886.041 2025.95 815.59 2037.27 763.643 2057.43C711.697 2077.58 682.508 2104.91 682.498 2133.41C682.488 2161.91 711.657 2189.24 763.59 2209.4C815.523 2229.56 885.965 2240.89 959.424 2240.89H959.576Z"
                      stroke="#61DAFB"
                      strokeWidth="18"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_225_334"
              x="-50"
              y="2716"
              width="4282"
              height="4331"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="25" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.223529 0 0 0 0 0.0235294 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_225_334"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_225_334"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_225_334"
              x="669.498"
              y="1888.87"
              width="580.004"
              height="517.675"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_225_334"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_225_334"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_225_334"
              x1="1013.7"
              y1="5649.37"
              x2="3165.69"
              y2="3697.79"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DB0909" />
              <stop offset="0.21" stopColor="#E43806" />
              <stop offset="0.47" stopColor="#F06C03" />
              <stop offset="0.7" stopColor="#F89201" />
              <stop offset="0.89" stopColor="#FDAA00" />
              <stop offset="1" stopColor="#FFB300" />
            </linearGradient>
          </defs>
        </svg>

        {/****************
         **    Stars    **
         ****************/}
        <div className="star star1"></div>
        <div className="star star2"></div>
        <div className="star star3"></div>
        <div className="star star4"></div>
      </div>
    </section>
  );
};

export default Hero;
